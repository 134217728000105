import React from "react";
import { CenterOnPage } from "../core/CenterOnPage";
import { Box, Card, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { Feedback } from "@mui/icons-material";
import { SideMenuOption } from "../core/SideMenuOption";
import { DiscordLink, SupportEmail } from "../core/CommonLinks";

export const FeedbackAndSupportButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = () => {
    navigate("/support");
  };

  return (
    <SideMenuOption
      label="Support"
      tooltip="Get support or give feedback"
      onClick={onClickHandler}
      selected={location.pathname.startsWith("/support")}
    >
      <Feedback />
    </SideMenuOption>
  );
};

export const FeedbackAndSupportPage = () => {
  return (
    <CenterOnPage>
      <Card>
        <Box style={{ width: "60vw", height: "60vh" }} p={2}>
          <Box pb={2}>
            <Typography textAlign="center" variant="h4">
              Feedback and Support
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography>
              Need support? Want to tell us you're enjoying Pitch2Table? Want to suggest a feature? Would you like us to
              delete your data?
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography>
              Email us: <SupportEmail /> or join our <DiscordLink />.
            </Typography>
          </Box>
        </Box>
      </Card>
    </CenterOnPage>
  );
};

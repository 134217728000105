import React from "react";
import { Box, Grid } from "@mui/material";
import { useLayoutMode } from "../layout/use-layout-mode";

/**
 * @param {object} props
 * @param {() => React.JSX.Element} props.ListView
 * @param {() => React.JSX.Element} props.DetailView
 */
export const ListDetail = ({ ListView, DetailView }) => {
  const { showLeft, showRight, singleView } = useLayoutMode();

  return (
    <Box p={2} width="100%" flex={1}>
      <Grid container spacing={2}>
        {showLeft && (
          <Grid item xs={singleView ? 12 : 6}>
            <ListView />
          </Grid>
        )}
        {showRight && (
          <Grid item xs={singleView ? 12 : 6}>
            <DetailView />
          </Grid>
        )}
      </Grid>
    </Box>
  );
};

import React from "react";
import { Box, Card, List, ListItem, ListItemIcon, ListItemText, Stack, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { SideMenuOption } from "../core/SideMenuOption";
import CampaignIcon from "@mui/icons-material/Campaign";
import { BugReport } from "@mui/icons-material";

export const UpdatesButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = () => {
    navigate("/updates");
  };

  return (
    <SideMenuOption
      label="Updates"
      tooltip="Keep up to date with the latest features"
      onClick={onClickHandler}
      selected={location.pathname.startsWith("/updates")}
    >
      <CampaignIcon />
    </SideMenuOption>
  );
};

export const UpdatesPage = () => {
  return (
    <Box p={2} width="100%" flex={1}>
      <Card>
        <Box p={2}>
          <Box pb={2}>
            <Typography textAlign="center" variant="h4">
              Release Notes
            </Typography>
          </Box>
          <Box pt={2} pb={2}>
            <List>
              <ListItem>
                <ListItemText primary="November 2024" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="Updates" />
              </ListItem>
              <ListItem>
                <Stack direction="column">
                  <ListItemText secondary="Contracts view now shows all your contracts across all publishers." />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemText primary="October 2024" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="Updates" />
              </ListItem>
              <ListItem>
                <Stack direction="column">
                  <ListItemText secondary="You can now see all your contracts organised by publisher." />
                  <ListItemText secondary="View all your submissions organised by Publisher." />
                  <ListItemText secondary="You can track per-publisher which version of the rules of sellsheet you have sent them." />
                  <ListItemText secondary="You can now filter publishers during shortlist." />
                  <ListItemText secondary="Games can now have a long description." />
                  <ListItemText secondary="Per publisher, you can keep track of which version of a sellsheet or rules document you have sent them" />
                  <ListItemText secondary="Record whether the publisher has access to your PnP files" />
                  <ListItemText secondary="Support for linking to your submission on inventor relations portals for companies like Hasbro and Mattel." />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BugReport />
                </ListItemIcon>
                <ListItemText primary="Bug Fixes" />
              </ListItem>
              <ListItem>
                <Stack direction="column">
                  <ListItemText secondary="Fixed refreshing screen would return user to games list." />
                  <ListItemText secondary="Fixed alignment of visibility toggle." />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemText primary="September 2024" />
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <CampaignIcon />
                </ListItemIcon>
                <ListItemText primary="Updates" />
              </ListItem>
              <ListItem>
                <Stack direction="column">
                  <ListItemText secondary="Notification settings can be found in your account menu" />
                  <ListItemText secondary="Publisher follows ups. Keep track of when you can follow up with a publisher. This is managed on a per-pitch level." />
                  <ListItemText secondary="Pitch status is now separate from publisher updates. Use the drop down to set the pitch status, use the + to add an update." />
                  <ListItemText secondary="New pitch status: 'Pitch Sent', 'Under Consideration' and 'In Contract Negotiation'" />
                  <ListItemText secondary="Publisher Interest for a game now shows when next follow up is due." />
                  <ListItemText secondary="Contract Management added. You can now securely upload your signed contracts so that you and your co-designer will always know exactly where that contract is. You can also download or open the contract in another tab for fullscreen reading." />
                  <ListItemText secondary="Layout button to switch between single column and two-column layout. The app will choose the best initial view based on the window dimensions." />
                </Stack>
              </ListItem>
              <ListItem>
                <ListItemIcon>
                  <BugReport />
                </ListItemIcon>
                <ListItemText primary="Bug Fixes" />
              </ListItem>
              <ListItem>
                <Stack direction="column">
                  <ListItemText secondary="Fixed website login not fitting on mobile devices" />
                  <ListItemText secondary="Fixed bug where you could only shortlist 1-9 publishers, not 10." />
                  <ListItemText secondary="Shortlisting a publisher no longer adds an update. This behaviour was making back-dating publisher communications tedious." />
                  <ListItemText secondary="Improve invite co-designer page when profile is incomplete." />
                  <ListItemText secondary="Fixed input validation now only shows up after the user has made a change." />
                  <ListItemText secondary="Fixed contrast of action button on pop-ups." />
                  <ListItemText secondary="Fixed issue where rules documents (PDF) were not showing after uploading." />
                  <ListItemText secondary="Fixed content showing too wide on Windows desktop devices resulting in horizontal scrolling" />
                  <ListItemText secondary="Side menu now responds to device width and includes text on when it will fit. Tooltips now included at all resolutions." />
                  <ListItemText secondary="Fixed bug where new users would sometimes not get a profile created. We also fixed the issue where new users that were getting a profile created were being given the name undefined." />
                </Stack>
              </ListItem>
            </List>
          </Box>
        </Box>
      </Card>
    </Box>
  );
};

import React, { useRef, useState } from "react";
import { Box, Chip, Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import {
  ArrowForwardOutlined,
  Check,
  ContentCopy,
  EditOffOutlined,
  EditOutlined,
  OpenInNewOutlined,
} from "@mui/icons-material";
import { enqueueSnackbar } from "notistack";
import { Link as RouterLink } from "react-router-dom";
import { useCopyToClipboard, useHover } from "usehooks-ts";
import { Path } from "../Path";
import { UploadRulesComponent } from "./UploadRulesComponent";
import { useGameRules } from "@seabrookstudios/pitch2table";

export const UploadedRules = ({ gameId, url }) => {
  const { data: rules = [] } = useGameRules(gameId);

  const [, copy] = useCopyToClipboard();
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  const copyUrlToClipboard = () => {
    copy(url);
    enqueueSnackbar("Url copied to clipboard", { variant: "info" });
  };

  return (
    <ListItem ref={hoverRef}>
      <ListItemText primary="Rules" secondary={url ? `Versions uploaded: ${rules.length}` : "Not uploaded"} />
      {isHover && url && (
        <>
          <ListItemIcon>
            <ListItemButton onClick={copyUrlToClipboard}>
              <ContentCopy />
            </ListItemButton>
          </ListItemIcon>
          <a href={url} target="_blank" rel="noreferrer">
            <ListItemIcon>
              <ListItemButton>
                <OpenInNewOutlined />
              </ListItemButton>
            </ListItemIcon>
          </a>
        </>
      )}
      {url && (
        <ListItemIcon>
          <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
            <Chip icon={<Check />} label="DONE" color="success" />
          </Box>
        </ListItemIcon>
      )}
      <Link to={Path.game(gameId).rules()} component={RouterLink}>
        <ListItemIcon>
          <ListItemButton>
            <ArrowForwardOutlined />
          </ListItemButton>
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};

import React, { useEffect, useState } from "react";
import { PublisherSubmissionsData } from "../publisher/PublisherSubmissionsData";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").PublisherGame[]} props.submissions
 * @param {ReactQueryStatus} props.submissionsStatus
 * @param {(React.JSX.Element)} props.children
 */
const AddIsInQueueBase = ({ children, submissions, submissionsStatus, gameId }) => {
  const [status, setStatus] = useState(submissionsStatus);
  const [isInQueue, setIsInQueue] = useState(!!submissions.find((publisherGame) => publisherGame.gameId === gameId));

  useEffect(() => {
    setIsInQueue(!!submissions.find((publisherGame) => publisherGame.gameId === gameId));
    setStatus("success");
  }, [submissions, gameId]);

  return <>{React.cloneElement(children, { isInQueue, isInQueueStatus: status })}</>;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {(React.JSX.Element)} props.children
 */
export const AddIsInQueue = ({ children, gameId }) => {
  return (
    <PublisherSubmissionsData>
      <AddIsInQueueBase gameId={gameId} submissions={[]} submissionsStatus="loading">
        {children}
      </AddIsInQueueBase>
    </PublisherSubmissionsData>
  );
};

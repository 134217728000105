import React from "react";
import { useMyPublishers } from "@seabrookstudios/pitch2table";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import { Alert, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import { PublisherLogoSquare2 } from "../publisher/PublisherLogo";
import { usePublisherIdFromRoute } from "../games/WithGameIdFromRoute";
import { MyPublisherDetailFromRoute } from "./MyPublisherDetailFromRoute";
import { formatSubmissions } from "./model";

const NoPublishersFound = () => (
  <NoItemsFound>
    <Alert severity="info">
      Each publisher that you have some relationship with will show here. At the moment this is every publisher that you
      have shortlisted for a pitch.
    </Alert>
  </NoItemsFound>
);

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").ContactBookPublisher} props.item
 */
export const MyPublisherListEntry = ({ item }) => {
  const publisherId = usePublisherIdFromRoute();
  const navigate = useNavigate();

  const selected = publisherId === item.id;

  const onClickHandler = () => {
    navigate(Path.publisher(item.id).link());
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <PublisherLogoSquare2 publisher={item} width={48} height={48} />
      </ListItemAvatar>
      <ListItemText primary={item.name} secondary={formatSubmissions(item.games)} />
    </ListItemButton>
  );
};

export const PublishersListAndDetail = () => {
  const useMakeList = useMakeListBuilder(MyPublisherListEntry, idExtractor, "My Publishers", NoPublishersFound);

  const ListAndDetail = useMakeListAndDetail(
    useMyPublishers,
    [],
    "publishers",
    useMakeList,
    MyPublisherDetailFromRoute
  );

  return <ListAndDetail />;
};

import React from "react";
import { ListView } from "../core/ListView";
import { Badge, ListItemAvatar, ListItemButton, ListItemText } from "@mui/material";
import { useInvitesForUser } from "@seabrookstudios/pitch2table";
import { AccountBox, Business, ContactPage, Inbox, LocationCity, Notifications } from "@mui/icons-material";

/**
 * @param {Object} props
 * @param {string} props.id
 * @param {string} props.category
 * @param {string} props.helperText
 * @param {boolean} props.selected
 * @param {Function} props.onClick
 * @param {number} props.badgeCount
 * @param {import("@mui/material/OverridableComponent").OverridableComponent<import("@mui/material").SvgIconTypeMap<{}, "svg">>} props.Icon
 */
const SettingsCategory = ({ id, category, helperText, selected, onClick, badgeCount = 0, Icon }) => {
  const onClickHandler = () => {
    onClick(id);
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <Badge color="primary" badgeContent={badgeCount}>
          <Icon />
        </Badge>
      </ListItemAvatar>
      <ListItemText primary={category} secondary={helperText} />
    </ListItemButton>
  );
};

export const DesignerSettingsList = ({ onListItemSelected, selectedListItemId }) => {
  const { data: invites = [] } = useInvitesForUser();

  return (
    <ListView title="Settings" Filters={null} Action={null} neverEmpty>
      <SettingsCategory
        category="About You"
        id="about-you"
        helperText="Adjust your name and other settings about you."
        selected={selectedListItemId === "about-you"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={AccountBox}
      />
      <SettingsCategory
        category="Location"
        id="your-location"
        helperText="Set your location and timezone."
        selected={selectedListItemId === "your-location"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={LocationCity}
      />
      <SettingsCategory
        category="Notifications"
        id="notifications"
        helperText="Control how we contact you and when."
        selected={selectedListItemId === "notifications"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={Notifications}
      />
      <SettingsCategory
        category="Invites"
        id="invites"
        helperText="Manage invitations to co-design or join publishers."
        selected={selectedListItemId === "invites"}
        onClick={onListItemSelected}
        badgeCount={invites.length}
        Icon={Inbox}
      />
    </ListView>
  );
};

export const PublisherSettingsList = ({ onListItemSelected, selectedListItemId }) => {
  return (
    <ListView title="Publisher Settings" Filters={null} Action={null} neverEmpty>
      <SettingsCategory
        category="Publisher Info"
        id="about-publisher"
        helperText="Adjust your company information."
        selected={selectedListItemId === "about-publisher"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={Business}
      />
      <SettingsCategory
        category="Contact Information"
        id="contact"
        helperText="Preferred method of contact."
        selected={selectedListItemId === "contact"}
        onClick={onListItemSelected}
        badgeCount={0}
        Icon={ContactPage}
      />
    </ListView>
  );
};

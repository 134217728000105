/**
 * @param {import("@seabrookstudios/types").Url} url
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {string} name
 * @param {Function} onSuccess
 * @param {Function} onError
 */
export const useUploadImage = (url, token, name, onSuccess, onError) => {
  const process = async (_fieldName, file, _metadata, load, error, progress, abort) => {
    const formData = new FormData();
    formData.append(name, file, file.name);

    const request = new XMLHttpRequest();
    request.open("POST", url);
    request.setRequestHeader("Authorization", `Bearer ${token}`);

    request.upload.onprogress = (e) => {
      progress(e.lengthComputable, e.loaded, e.total);
    };

    request.onload = function () {
      if (request.status >= 200 && request.status < 300) {
        load(request.responseText);
        onSuccess();
      } else {
        error("oh no");
        onError();
      }
    };

    request.send(formData);

    return {
      abort: () => {
        request.abort();
        abort();
      },
    };
  };

  return process;
};

import React from "react";
import { PublisherLogoSquare } from "../../publisher/PublisherLogo";
import { UserAvatar } from "../../users/UserAvatar";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherGameUpdate} props.update
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisherGame
 */
export const PublisherUpdateOwnerIcon = ({ update, publisherGame }) => {
  if (update.createdByUserId === "publisher") {
    return <PublisherLogoSquare width={40} height={40} publisher={publisherGame} />;
  }

  return <UserAvatar userId={update.createdByUserId} />;
};

import React from "react";
import { ListDetail } from "../web/ListDetail";
import { RulesList } from "./RulesList";
import { RulesFromRoute } from "./RulesPreview";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { useGameRules } from "@seabrookstudios/pitch2table";
import { Path } from "../Path";

export const RulesListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data: rules = [] } = useGameRules(gameId);

  const SelectedRules = () => <RulesFromRoute />;

  const ListView = () => <RulesList gameId={gameId} rules={rules} back={Path.game(gameId).link()} />;

  return <ListDetail ListView={ListView} DetailView={SelectedRules} />;
};

import React from "react";
import { ListItemButton, ListItemText } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../../Path";
import { formatFullDate } from "../../core/date-formatting";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").Contract} props.contract
 */
export const ListEntry = ({ contract }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.attachmentId === contract.id;

  const onClickHandler = () => {
    navigate(Path.game(contract.gameId).contract(contract.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText primary={contract.name} secondary={`Signed ${formatFullDate(contract.signedDate)}`} />
    </ListItemButton>
  );
};

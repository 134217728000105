import React, { useContext } from "react";
import { CenterOnPage } from "./CenterOnPage";
import {
  Box,
  Card,
  CircularProgress,
  List,
  ListItemButton,
  ListItemIcon,
  ListItemText,
  Typography,
} from "@mui/material";
import { useMyPublishers } from "../publisher/queries";
import { ErrorRounded, PlayArrow } from "@mui/icons-material";
import { AccountContext } from "./AccountProvider";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";

export const SelectAccountPage = () => {
  const navigate = useNavigate();
  const { setDesignerMode, setPublisherMode, mode, publisherId } = useContext(AccountContext);
  const { data: publishers = [], isLoading } = useMyPublishers();

  const onDesignerClickHandler = () => {
    setDesignerMode();
    navigate(Path.Home);
  };

  return (
    <CenterOnPage>
      <Card>
        <Box style={{ width: "80vw", height: "80vh" }} p={2}>
          <Typography textAlign="center" variant="h3">
            SELECT ACCOUNT
          </Typography>
          <List>
            <ListItemButton onClick={onDesignerClickHandler}>
              <ListItemIcon>{mode === "Designer" && <PlayArrow />}</ListItemIcon>
              <ListItemText primary="My Designer Account" />
            </ListItemButton>
            {isLoading && <CircularProgress />}
            {!isLoading && !publishers && <ErrorRounded />}
            {!isLoading &&
              publishers &&
              publishers.map((publisher) => {
                const onPublisherClickHandler = () => {
                  setPublisherMode(publisher.id);
                  navigate(Path.Home);
                };

                return (
                  <ListItemButton key={publisher.id} onClick={onPublisherClickHandler}>
                    <ListItemIcon>{mode === "Publisher" && publisher.id === publisherId && <PlayArrow />}</ListItemIcon>
                    <ListItemText primary={publisher.name} />
                  </ListItemButton>
                );
              })}
          </List>
        </Box>
      </Card>
    </CenterOnPage>
  );
};

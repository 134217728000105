import React, { useRef, useState } from "react";
import { Box, CircularProgress } from "@mui/material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { PdfViewer } from "../core/PdfViewer";
import { assetPath } from "../endpoints";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Sellsheet|import("@seabrookstudios/pitch2table-core").Rules|null|undefined} props.document
 */
export const ImageOrPdfViewer = ({ document }) => {
  const [loaded, setLoaded] = useState(false);
  const [hideImage, setHideImage] = useState(false);

  const ref = useRef(null);
  const [{ width, height }, setSize] = useState({
    width: 0,
    height: 0,
  });

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    ref,
    // @ts-expect-error
    onResize,
  });

  if (!document) {
    return <EmptyDetailCard text="Select entry from list on left." />;
  }

  const useableWidth = width - 48;

  return (
    <Box p={2} pt={2} ref={ref} justifyContent="center">
      <Box>
        {!hideImage && (
          <Box display="flex" justifyContent="center">
            {!loaded && <CircularProgress />}
            <img
              onLoad={() => setLoaded(true)}
              style={{ width: loaded ? useableWidth : 0 }}
              src={assetPath(document.s3location)}
              alt="uploaded document"
            />
          </Box>
        )}
      </Box>
      <PdfViewer
        path={assetPath(document.s3location)}
        width={width}
        height={height}
        notifyOthers={() => setHideImage(true)}
      />
    </Box>
  );
};

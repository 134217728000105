import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { DesignerGameStatusHelperText, DesignerGameStatusToLabel } from "./model";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").DesignerGameStatus} props.value
 * @param {Function} props.onChange
 */
export const StatusDropDown = ({ value, onChange }) => {
  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal">
      <InputLabel id="game-status">Status</InputLabel>
      <Select
        labelId="game-status"
        value={value}
        onChange={onChangeHandler}
        placeholder="status"
        label="Status"
        input={<OutlinedInput label="Status" />}
      >
        <MenuItem value="Idea">{DesignerGameStatusToLabel.Idea}</MenuItem>
        <MenuItem value="InDevelopmentEarly">{DesignerGameStatusToLabel.InDevelopmentEarly}</MenuItem>
        <MenuItem value="InDevelopmentMid">{DesignerGameStatusToLabel.InDevelopmentMid}</MenuItem>
        <MenuItem value="InDevelopmentLate">{DesignerGameStatusToLabel.InDevelopmentLate}</MenuItem>
        <MenuItem value="PitchingNow">{DesignerGameStatusToLabel.PitchingNow}</MenuItem>
        <MenuItem value="Signed">{DesignerGameStatusToLabel.Signed}</MenuItem>
        <MenuItem value="Published">{DesignerGameStatusToLabel.Published}</MenuItem>
      </Select>
      <FormHelperText>{DesignerGameStatusHelperText[value] || ""}</FormHelperText>
    </FormControl>
  );
};

import React, { useEffect, useState } from "react";
import { SmartTextField } from "./SmartTextField";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { StatusDropDown } from "./StatusDropDown";
import { useSaveGame } from "@seabrookstudios/pitch2table";

const GameNameTextField = ({ onChange }) => {
  return (
    <SmartTextField required label="Game Name" helperText="A good pitch starts with a good name." onChange={onChange} />
  );
};

const GameTagTextField = ({ onChange }) => {
  return <SmartTextField label="Tag Line" helperText="Your game is 25 words or less." onChange={onChange} />;
};

export default function AddGameFullScreenDialog() {
  const saveGame = useSaveGame();

  const [name, setName] = useState("");
  const [tag, setTag] = useState("");
  const [status, setStatus] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").DesignerGameStatus} */ ("Idea")
  );
  const [canSave, setCanSave] = useState(false);

  const onSaveHandler = async () => {
    await saveGame.mutateAsync({ name, tag, status });
  };

  useEffect(() => {
    setCanSave(!!name);
  }, [name, tag]);

  return (
    <FullScreenDialog
      title="Add Game"
      actionText="save"
      onSaveHandler={onSaveHandler}
      canSave={!canSave}
      isBusy={saveGame.isPending}
    >
      <GameNameTextField onChange={setName} />
      <GameTagTextField onChange={setTag} />
      <StatusDropDown value={status} onChange={setStatus} />
    </FullScreenDialog>
  );
}

import React, { useContext } from "react";
import { FilePond, registerPlugin } from "react-filepond";
import FilePondPluginImageValidateSize from "filepond-plugin-image-validate-size";
import FilePondPluginImageCrop from "filepond-plugin-image-crop";
import FilePondPluginFileValidateType from "filepond-plugin-file-validate-type";
import FilePondPluginImagePreview from "filepond-plugin-image-preview";
import FilePondPluginImageResize from "filepond-plugin-image-resize";
import FilePondPluginImageTransform from "filepond-plugin-image-transform";
import FilePondPluginFileValidateSize from "filepond-plugin-file-validate-size";
import "filepond/dist/filepond.min.css";
import "filepond-plugin-image-preview/dist/filepond-plugin-image-preview.css";
import { enqueueSnackbar } from "notistack";
import { useQueryClient } from "@tanstack/react-query";
import endpoints from "../endpoints";
import { s2Url } from "@seabrookstudios/types";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { useUploadImage } from "./queries";
import { ThisUser } from "../games/query-keys";

registerPlugin(FilePondPluginImagePreview);
registerPlugin(FilePondPluginImageValidateSize);
registerPlugin(FilePondPluginImageCrop);
registerPlugin(FilePondPluginFileValidateType);
registerPlugin(FilePondPluginImageResize);
registerPlugin(FilePondPluginImageTransform);
registerPlugin(FilePondPluginFileValidateSize);

export const UploadUserAvatar = () => {
  const queryClient = useQueryClient();
  const token = useContext(CachedTokenContext);

  const onFileUploadSuccess = () => {
    enqueueSnackbar("Avatar updated", { variant: "success" });
    queryClient.invalidateQueries({ queryKey: ThisUser() });
  };

  const onFileUploadFailure = () => {
    enqueueSnackbar("An error occured uploading the avatar", { variant: "error" });
  };

  const { users } = endpoints();
  const url = users().avatar();
  const uploadImage = useUploadImage(s2Url(url), token, "avatar", onFileUploadSuccess, onFileUploadFailure);

  return (
    <FilePond
      allowMultiple={false}
      allowReplace={false}
      allowRevert={false}
      allowProcess={false}
      credits={false}
      instantUpload={true}
      maxFiles={1}
      labelIdle={'Drag & Drop your avatar or <span class="filepond--label-action"> Browse </span>'}
      allowImageResize
      imageResizeTargetWidth={1024}
      imageResizeTargetHeight={1024}
      maxFileSize="5MB"
      allowImageTransform
      imageResizeMode="contain"
      acceptedFileTypes={["image/png", "image/jpeg"]}
      fileValidateTypeLabelExpectedTypesMap={{ "image/png": "png", "image/jpeg": "jpg" }}
      server={{
        process: uploadImage,
      }}
    />
  );
};

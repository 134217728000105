import React, { useState } from "react";
import { Document, Page } from "react-pdf";
import { Box, Button, CircularProgress, Stack, Typography } from "@mui/material";
import { FirstPage, LastPage, NavigateBefore, NavigateNext } from "@mui/icons-material";

import "react-pdf/dist/esm/Page/AnnotationLayer.css";
import "react-pdf/dist/esm/Page/TextLayer.css";

/**
 * @param {object} props
 * @param {string|null|undefined} props.path
 * @param {number} props.width
 * @param {number} props.height
 * @param {Function|null} [props.notifyOthers]
 */
export const PdfViewer = ({ path, width, height, notifyOthers = null }) => {
  const [numPages, setNumPages] = useState(0);
  const [pageNumber, setPageNumber] = useState(1);
  const [error, setError] = useState(false);

  const onDocumentLoadSuccess = ({ numPages }) => {
    setNumPages(numPages);

    if (notifyOthers) {
      notifyOthers();
    }
  };

  const onLoadError = () => {
    setError(true);
  };

  if (error) {
    return null;
  }

  if (!path) {
    return null;
  }

  return (
    <>
      <Box sx={{ border: 1, borderColor: "black", borderStyle: "solid" }}>
        <Document
          file={path}
          onLoadSuccess={onDocumentLoadSuccess}
          onLoadError={onLoadError}
          loading={<CircularProgress />}
        >
          <Page pageNumber={pageNumber} width={width - 48} height={height - 16} />
        </Document>
      </Box>
      <Stack direction="row" flex={1} justifyContent="space-between">
        <Button onClick={() => setPageNumber(1)} disabled={pageNumber === 1}>
          <FirstPage />
        </Button>
        <Button onClick={() => setPageNumber((current) => current - 1)} disabled={pageNumber === 1}>
          <NavigateBefore />
        </Button>
        <Typography display="flex" alignItems="center">
          {pageNumber}/{numPages}
        </Typography>
        <Button onClick={() => setPageNumber((current) => current + 1)} disabled={pageNumber === numPages}>
          <NavigateNext />
        </Button>
        <Button onClick={() => setPageNumber(numPages)} disabled={pageNumber === numPages}>
          <LastPage />
        </Button>
      </Stack>
    </>
  );
};

import React from "react";
import { ListDetail } from "../web/ListDetail";
import { PublisherSettingsList } from "../settings/SettingsList";
import { AboutPublisher } from "../settings/AboutPublisher";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { PublisherContact } from "../settings/PublisherContact";
import { useSettingsCategoryFromRoute } from "../settings/params";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";

export const PublisherSettings = () => {
  const category = useSettingsCategoryFromRoute();
  const navigate = useNavigate();

  const onListItemSelected = (id) => {
    navigate(Path.settings().category(id));
  };

  const SelectedEntry = () => {
    switch (category) {
      case "about-publisher":
        return <AboutPublisher />;
      case "contact":
        return <PublisherContact />;
      default:
        return <EmptyDetailCard text="Select setting from list on left" />;
    }
  };

  const ListView = () => (
    <PublisherSettingsList selectedListItemId={category} onListItemSelected={onListItemSelected} />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedEntry} />;
};

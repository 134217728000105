import React, { useRef, useState } from "react";
import {
  Box,
  Card,
  CircularProgress,
  List,
  ListItem,
  ListItemButton,
  ListItemIcon,
  Stack,
  Typography,
} from "@mui/material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useDebounceCallback, useResizeObserver } from "usehooks-ts";
import { PdfViewer } from "../core/PdfViewer";
import { useAttachmentIdFromRoute, useGameIdFromRoute, usePublisherIdFromRoute } from "../games/WithGameIdFromRoute";
import {
  useDocument,
  useGameContract,
  useGameContracts,
  useMyDocuments,
  useMyDocumentsRelatingToPublisher,
  useNonGameContract,
  useUpdateContract,
  useUpdateNonGameContract,
} from "@seabrookstudios/pitch2table";
import { enqueueSnackbar } from "notistack";
import { LastUpdated } from "../core/LastUpdated";
import { EditableListItemText } from "../core/EditableListItemText";
import { EditableDate } from "../core/EditableDate";
import { Download, Lock, OpenInNew } from "@mui/icons-material";
import { get } from "../network";

const LockedContract = () => {
  return (
    <Box
      display="flex"
      justifyContent="center"
      alignItems="center"
      flex={1}
      height="60vh"
      sx={{ backgroundColor: "#F6F5F5" }}
      flexDirection="column"
    >
      <Lock />
      <Typography>click to view contract</Typography>
    </Box>
  );
};

export const useDownloadFile = ({ url, onError }) => {
  const ref = useRef(/** @type {HTMLAnchorElement|null} */ (null));
  const [dataUrl, setFileUrl] = useState("");

  const download = async () => {
    try {
      const response = await get(url, {
        responseType: "blob",
      });
      const { data } = response;

      const dataUrl = URL.createObjectURL(new Blob([data]));
      setFileUrl(dataUrl);

      if (ref && ref.current) {
        ref.current.click();
      }

      URL.revokeObjectURL(dataUrl);
    } catch (error) {
      // TODO: Bugsnag
      console.log(error);
      onError();
    }
  };

  return { download, ref, dataUrl };
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").Url|undefined|null} props.url
 * @param {import("@seabrookstudios/pitch2table-core").Contract} props.contract
 */
const ContractPreviewBase = ({ contract, url }) => {
  const ref = useRef(null);
  const [{ width, height }, setSize] = useState({
    width: 0,
    height: 0,
  });

  const onResize = useDebounceCallback(setSize, 200);

  useResizeObserver({
    ref,
    // @ts-expect-error
    onResize,
  });

  const handleDownload = async () => {
    if (!url) {
      return;
    }

    try {
      const response = await fetch(url);

      const blob = await response.blob();

      const internalUrl = window.URL.createObjectURL(new Blob([blob]));
      const link = document.createElement("a");
      link.href = internalUrl;
      link.download = `${contract.name || "contract"}.pdf`;
      document.body.appendChild(link);

      link.click();

      document.body.removeChild(link);
      window.URL.revokeObjectURL(internalUrl);
    } catch (error) {
      enqueueSnackbar("Download failed", { variant: "error" });
    }
  };

  return (
    <Box p={2} pt={2} ref={ref} justifyContent="center">
      <Stack direction="row" justifyContent="flex-end">
        {url && (
          <ListItemIcon>
            <ListItemButton onClick={handleDownload}>
              <Download />
            </ListItemButton>
          </ListItemIcon>
        )}
        {url && (
          <a href={url} target="_blank" rel="noreferrer">
            <ListItemIcon>
              <ListItemButton>
                <OpenInNew />
              </ListItemButton>
            </ListItemIcon>
          </a>
        )}
      </Stack>
      <PdfViewer path={url} width={width} height={height} />
    </Box>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Contract} props.contract
 */
const ContractPreview = ({ contract }) => {
  const { data: url } = useGameContract(contract.gameId, contract.id);

  return <ContractPreviewBase url={url} contract={contract} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 * @param {import("@seabrookstudios/pitch2table-core").Contract} props.contract
 */
const NonGameContractPreview = ({ publisherId, contract }) => {
  const { data: url } = useNonGameContract(publisherId, contract.id);

  return <ContractPreviewBase url={url} contract={contract} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Contract|import("@seabrookstudios/pitch2table-core").NonGameContract} props.contract
 * @param {import("@tanstack/react-query").UseMutationResult<import("@seabrookstudios/pitch2table-core").EditableContract>} props.updateContract
 * @param {() => React.JSX.Element} props.UnlockedView
 */
const BaseContactFromRoute = ({ contract, updateContract, UnlockedView }) => {
  const [locked, setLocked] = useState(true);

  const unlock = () => {
    setLocked(false);
  };

  /**
   * @param {number} signedDate
   */
  const onUpdateDate = (signedDate) => {
    updateContract.mutate({ signedDate });
  };

  /**
   * @param {string} name
   */
  const onUpdateName = async (name) => {
    await updateContract.mutateAsync({ name });
  };

  if (!contract) {
    return <EmptyDetailCard text="Select a contract from the list on the left." />;
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <EditableListItemText
            primary="Document name"
            secondary={contract.name}
            onSave={onUpdateName}
            isBusy={updateContract.isPending}
            helperText="Your reference for this contract e.g. digital rights, NDA"
          />
        </ListItem>
        <ListItem>
          <EditableDate
            title="Signed date"
            timestamp={contract.signedDate}
            onSave={onUpdateDate}
            isBusy={updateContract.isPending}
          />
        </ListItem>
      </List>
      <Box onClick={unlock} p={2}>
        {locked ? <LockedContract /> : <UnlockedView />}
      </Box>
      <LastUpdated timestamp={contract.updated} />
    </Card>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} props.attachmentId
 */
export const GameContract = ({ gameId, attachmentId }) => {
  const { data: contracts = [] } = useGameContracts(gameId);
  const contract = contracts.find((c) => c.id === attachmentId);

  const onContractUpdate = () => {
    enqueueSnackbar("Updated", { variant: "success" });
  };

  const updateContract = useUpdateContract(gameId, attachmentId, onContractUpdate);

  return (
    <BaseContactFromRoute
      contract={contract}
      updateContract={updateContract}
      UnlockedView={() => <ContractPreview contract={contract} />}
    />
  );
};

export const ContractFromRoute = () => {
  const gameId = useGameIdFromRoute();
  const attachmentId = useAttachmentIdFromRoute();

  return <GameContract gameId={gameId} attachmentId={attachmentId} />;
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} props.attachmentId
 */
export const NonGameContract = ({ publisherId, attachmentId }) => {
  const { data: documents = [] } = useMyDocumentsRelatingToPublisher(publisherId);
  const contract = documents.find((c) => c.id === attachmentId);

  const onContractUpdate = () => {
    enqueueSnackbar("Updated", { variant: "success" });
  };

  const updateContract = useUpdateNonGameContract(publisherId, attachmentId, onContractUpdate);

  return (
    <BaseContactFromRoute
      contract={contract}
      updateContract={updateContract}
      UnlockedView={() => <NonGameContractPreview publisherId={publisherId} contract={contract} />}
    />
  );
};

export const NonGameContractFromRoute = () => {
  const publisherId = usePublisherIdFromRoute();
  const attachmentId = useAttachmentIdFromRoute();

  return <NonGameContract publisherId={publisherId} attachmentId={attachmentId} />;
};

export const DocumentFromRoute = () => {
  const attachmentId = useAttachmentIdFromRoute();

  const { data: document } = useDocument(attachmentId);

  if (!document) {
    return <CircularProgress />;
  }

  if (document.refType === "PublisherId") {
    return <NonGameContract publisherId={document.refId} attachmentId={document.id} />;
  }

  return <GameContract gameId={document.refId} attachmentId={document.id} />;
};

import React from "react";
import { ListView } from "../core/ListView";
import { Alert, Box, Chip, ListItemAvatar, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { PublisherStatus } from "./PublisherStatus";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { AddPublisherInterest } from "./publisher-interest/AddPublisherInterest";
import { PublisherLogoSquare } from "../publisher/PublisherLogo";
import { formatFollowUp, showAlarm } from "@seabrookstudios/pitch2table-core";
import { FollowUpDuePill } from "./FollowUpDuePill";
import { getSeverity } from "./publisher-interest/follow-up-severity";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisher
 */
const PublisherListEntry = ({ publisher }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.publisherId === publisher.publisherId;

  const onClickHandler = () => {
    navigate(Path.game(publisher.gameId).publisher(publisher.publisherId));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <PublisherLogoSquare publisher={publisher} />
      </ListItemAvatar>
      <ListItemText primary={publisher.publisherName} secondary={formatFollowUp(publisher)} />
      {showAlarm(publisher.nextAction) && <FollowUpDuePill severity={getSeverity(publisher.nextAction)} />}
      <Box p={0.5} />
      <ListItemIcon>
        <PublisherStatus status={publisher.status} />
      </ListItemIcon>
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {string} props.filter
 * @param {object[]} props.filters
 * @param {Function} props.onFilterClicked
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView[]} props.publishers
 * @param {Function} [props.refetch]
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.isRefetching]
 */
export const PublisherInterestList = ({
  onFilterClicked,
  publishers,
  filter,
  filters = [],
  refetch,
  isRefetching,
  isLoading,
}) => {
  const gameId = useGameIdFromRoute();

  const Filters = () => (
    <>
      {filters.map(({ Icon, label, count }) => {
        const onChipClicked = () => onFilterClicked(label);
        return (
          <Chip
            key={label}
            icon={Icon && <Icon />}
            label={`${label} (${count})`}
            onClick={onChipClicked}
            variant={label === filter ? "filled" : "outlined"}
          />
        );
      })}
    </>
  );

  const Action = () => <AddPublisherInterest gameId={gameId} />;

  return (
    <ListView
      back={Path.game(gameId).link()}
      title="Publisher Interest"
      Filters={Filters}
      Action={Action}
      EmptyList={() => (
        <NoItemsFound>
          <Alert severity="info">
            Track your interactions with each publisher. Start with a shortlist and then update each publisher after
            you've contacted them. Use in the in-built reminder system to know when it's time to reach out for an
            update.
          </Alert>
        </NoItemsFound>
      )}
      shown={publishers.length}
      isLoading={isLoading}
      isRefetching={isRefetching}
      refetch={refetch}
    >
      {publishers.map((publisher) => (
        <PublisherListEntry key={publisher.id.toString()} publisher={publisher} />
      ))}
    </ListView>
  );
};

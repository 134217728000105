import React from "react";
import { ListView } from "../core/ListView";
import { FilterList } from "../core/FilterList";
import AddGameFullScreenDialog from "./AddGameFullScreenDialog";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { GameListEntry } from "./ListItem";

/**
 * @param {object} props
 * @param {(label: string) => void} props.onFilterClicked
 * @param {import("@seabrookstudios/pitch2table-core").GameSummary[]} props.summaries
 * @param {string} props.filter
 * @param {Filter<import("@seabrookstudios/pitch2table-core").GameSummary>[]} props.filters
 * @param {Function} props.refetch
 * @param {boolean} props.isLoading
 * @param {boolean} props.isRefetching
 */
export const GamesList = ({ onFilterClicked, summaries, filter, filters = [], refetch, isLoading, isRefetching }) => {
  const Filters = () => <FilterList filters={filters} filter={filter} onFilterClicked={onFilterClicked} />;
  const Action = () => <AddGameFullScreenDialog />;

  return (
    <ListView
      title="My Games"
      Filters={Filters}
      Action={Action}
      shown={summaries.length}
      EmptyList={() => <NoItemsFound>No games found.</NoItemsFound>}
      refetch={refetch}
      isLoading={isLoading}
      isRefetching={isRefetching}
    >
      {summaries.map((item) => (
        <GameListEntry key={item.id.toString()} item={item} />
      ))}
    </ListView>
  );
};

import React from "react";
import { Box, Card, Typography } from "@mui/material";
import LoginButton from "../login/LoginButton";

export const EmptyDetailCard = ({ text }) => (
  <Card
    elevation={1}
    square={false}
    sx={{ height: "85vh", justifyContent: "center", alignItems: "center", flex: 1, display: "flex" }}
  >
    <Typography>{text}</Typography>
  </Card>
);

export const EmptyDetailCardWithLogin = ({ text }) => (
  <Card
    elevation={1}
    square={false}
    sx={{
      height: "85vh",
      justifyContent: "center",
      alignItems: "center",
      flex: 1,
      display: "flex",
      flexDirection: "column",
    }}
  >
    <Typography>{text}</Typography>
    <Box p={1}>
      <LoginButton overrideLabel="Login" />
    </Box>
  </Card>
);

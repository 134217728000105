import React from "react";
import { Box, ListItemText, Stack, Tooltip } from "@mui/material";
import { EditTextFieldDialog } from "../core/EditTextFieldDialog";

const Display = ({ title, value }) => (
  <ListItemText
    primary={title}
    secondary={value || "click to edit"}
    sx={{ maxWidth: "66ch", whiteSpace: "pre-wrap" }}
  />
);

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string|number} props.text
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 * @param {(text: (string|number)) => void} props.onSave
 */
export const EditableListItem = ({ title, text, helperText, onSave, disabled }) => {
  return (
    <Stack direction="row" display="flex" justifyContent="flex-end">
      {disabled ? (
        <Display title={title} value={text} />
      ) : (
        <Tooltip title="Click to edit">
          <Box>
            <EditTextFieldDialog
              onSave={onSave}
              title={title}
              initialValue={text}
              isBusy={false}
              helperText={helperText}
              multiline
            >
              <Display title={title} value={text} />
            </EditTextFieldDialog>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};

import React from "react";
import { Box, Chip, ListItemIcon } from "@mui/material";
import NotificationImportant from "@mui/icons-material/NotificationImportant";

export const FollowUpDuePill = ({ severity }) => (
  <ListItemIcon>
    <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
      <Chip icon={<NotificationImportant />} label="DUE" color={severity} />
    </Box>
  </ListItemIcon>
);

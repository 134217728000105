import React from "react";
import { Box, Card, Typography } from "@mui/material";
import { CenterOnPage } from "./CenterOnPage";
import { Heading } from "./Heading";
import logo from "../assets/logo.png";
import { useSearchParams } from "react-router-dom";
import { SupportEmail } from "./CommonLinks";

export const ErrorPage = () => {
  const [searchParams] = useSearchParams();

  return (
    <CenterOnPage>
      <Card style={{ maxWidth: "66ch" }}>
        <Box p={2}>
          <Box display="flex" justifyContent="center" p={1}>
            <img src={logo} width={100} alt="pitch2table.com logo" />
          </Box>
          <Heading textAlign="center" variant="h5">
            ERROR {searchParams.has("error") ? `(${searchParams.get("error")})` : ""}
          </Heading>
          <Box pt={2} pb={2}>
            <Typography textAlign="center">{searchParams.get("error_description")}</Typography>
            <Typography textAlign="center">
              We've encountered an error and have not been able to complete your request. Try again and if it's still
              not working, reach out to us at: <SupportEmail />
            </Typography>
          </Box>
        </Box>
      </Card>
    </CenterOnPage>
  );
};

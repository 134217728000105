import React from "react";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import { UploadContractComponent, UploadContractToPublisherComponent } from "./UploadContractComponent";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 */
export const AddContractDialog = ({ gameId }) => {
  return (
    <FullScreenDialog fullscreen={false} title="Securely Upload Contract" canSave={true}>
      <UploadContractComponent gameId={gameId} />
    </FullScreenDialog>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
export const AddContractToPublisher = ({ publisherId }) => {
  return (
    <FullScreenDialog fullscreen={false} title="Securely Upload Document" canSave={true}>
      <UploadContractToPublisherComponent publisherId={publisherId} />
    </FullScreenDialog>
  );
};

import endpoints from "../endpoints";
import { get, optionsAuth, post } from "../network";
import { s2email } from "../util/type-conversion";

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").Publisher[]>}
 */
export const fetchMyPublishers = async (token) => {
  const { publishers } = endpoints();
  const url = publishers().me();

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").Publisher>}
 */
export const fetchPublisher = async (token, publisherId) => {
  const { publishers } = endpoints();
  const url = publishers().info(publisherId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").PublisherGameSummary[]>}
 */
export const fetchSubmissions = async (token, publisherId) => {
  const { publishers } = endpoints();
  const url = publishers().submissions(publisherId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").PublisherGame>}
 */
export const fetchPublisherGame = async (token, publisherId, gameId) => {
  const { publishers } = endpoints();
  const url = publishers().game(publisherId, gameId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").PublisherContact>}
 */
export const fetchPublisherContact = async (token, publisherId) => {
  const { publishers } = endpoints();
  const url = publishers().contact(publisherId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").PublisherGame>}
 */
export const addGameToPublisher = async (token, publisherId, gameId) => {
  const { publishers } = endpoints();
  const url = publishers().game(publisherId, gameId);

  const body = {};
  const response = await post(url, body, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {string} contactName
 * @param {string} pronouns
 * @param {import("@seabrookstudios/pitch2table-core").Email} email
 * @param {string} number
 * @returns {Promise<>}
 */
const updateContact = async (token, publisherId, contactName = "", pronouns = "", email = s2email(""), number = "") => {
  const { publishers } = endpoints();
  const url = publishers().contact(publisherId);

  /**
   * @type {import("@seabrookstudios/pitch2table-core").UpdatePublisherContactRequest}
   */
  const body = {
    contactName,
    email,
    number,
    pronouns,
  };

  const response = await post(url, body, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {string} contactName
 * @returns {Promise<>}
 */
export const updateContactName = async (token, publisherId, contactName) => {
  return updateContact(token, publisherId, contactName);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {string} pronouns
 * @returns {Promise<>}
 */
export const updateContactPronouns = async (token, publisherId, pronouns) => {
  return updateContact(token, publisherId, "", pronouns);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {import("@seabrookstudios/pitch2table-core").Email} email
 * @returns {Promise<>}
 */
export const updateContactEmail = async (token, publisherId, email) => {
  return updateContact(token, publisherId, "", "", email);
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {string} number
 * @returns {Promise<>}
 */
export const updateContactNumber = async (token, publisherId, number) => {
  return updateContact(token, publisherId, "", "", s2email(""), number);
};

import { useQuery } from "@tanstack/react-query";
import { fetchLatestSellsheetForGame, fetchSellsheetsForGame } from "../games/games-service";
import { AllSellsheetsByGameId, LatestSellsheetByGameId } from "../games/query-keys";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { useContext } from "react";

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const useLatestSellsheet = (gameId) => {
  return useQuery({
    queryKey: LatestSellsheetByGameId(gameId),
    queryFn: async () => {
      const sellsheets = await fetchLatestSellsheetForGame(gameId);
      return sellsheets;
    },
  });
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const useAllSellsheetsForGameId = (gameId) => {
  const token = useContext(CachedTokenContext);

  return useQuery({
    queryKey: AllSellsheetsByGameId(gameId),
    queryFn: async () => {
      const sellsheets = await fetchSellsheetsForGame(token, gameId);
      sellsheets.sort((a, b) => b.uploadedTimestamp - a.uploadedTimestamp);

      return sellsheets;
    },
  });
};

import React from "react";
import { ListView } from "../../core/ListView";
import { NoItemsFound } from "../../publisher/NoItemsFound";
import { ListEntry } from "./ListEntry";
import { AddContractDialog } from "./AddContractDialog";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").Contract[]} props.contracts
 * @param {import("react-router-dom").To} props.back
 * @param {Function} [props.refetch]
 * @param {boolean} [props.isLoading]
 * @param {boolean} [props.isRefetching]
 */
export const List = ({ gameId, contracts = [], back, refetch, isLoading, isRefetching }) => {
  const Action = () => <AddContractDialog gameId={gameId} />;

  return (
    <ListView
      title="Contracts"
      Filters={null}
      Action={Action}
      back={back}
      EmptyList={() => <NoItemsFound>No contracts found.</NoItemsFound>}
      shown={contracts.length}
      refetch={refetch}
      isLoading={isLoading}
      isRefetching={isRefetching}
    >
      {contracts.map((contract) => (
        <ListEntry key={contract.id.toString()} contract={contract} />
      ))}
    </ListView>
  );
};

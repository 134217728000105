import React, { useCallback, useEffect, useState } from "react";
import { TextField } from "@mui/material";

/**
 * @param {object} props
 * @param {string} [props.value]
 * @param {string|number} [props.initialValue]
 * @param {(text: (string|number)) => void} props.onChange
 * @param {(text: (string|number)) => void} [props.onEnter]
 * @param {boolean} [props.onEnterDisabled]
 * @param {boolean} [props.multiline]
 * @param {boolean} [props.autoFocus]
 * @param {number} [props.minRows]
 * @param {boolean} [props.required]
 * @param {string} props.label
 * @param {string} [props.helperText]
 */
export const SmartTextField = ({
  initialValue = "",
  value,
  onChange,
  onEnterDisabled = true,
  onEnter,
  multiline = false,
  autoFocus = false,
  required = false,
  helperText = "",
  minRows = 1,
  label,
  ...props
}) => {
  const [name, setName] = useState(initialValue);
  const [changed, setHasChanged] = useState(false);
  const [isValid, setValidStatus] = useState(true);

  const onChangeHandler = (ev) => {
    setHasChanged(true);
    setName(ev.target.value);
  };

  const onKeyDownHandler = (ev) => {
    if (onEnterDisabled) {
      return;
    }
    if (ev.key !== "Enter") {
      return;
    }

    if (onEnter) {
      onEnter(name);
    }
  };

  const validate = useCallback(() => {
    if (`${name}`.length === 0) {
      onChange("");
      setValidStatus(false);
      return false;
    }

    setValidStatus(true);
    onChange(name);
    return true;
  }, [setValidStatus, name, onChange]);

  useEffect(() => {
    if (!changed) {
      return;
    }

    validate();
  }, [name, validate, changed]);

  return (
    <TextField
      defaultValue={initialValue}
      fullWidth
      type="text"
      margin="dense"
      multiline={multiline}
      minRows={minRows}
      autoFocus={autoFocus}
      label={label}
      required={required}
      helperText={helperText}
      {...props}
      error={required && !isValid}
      onChange={onChangeHandler}
      onKeyDown={onKeyDownHandler}
    />
  );
};

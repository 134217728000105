import React from "react";
import { ListItemButton, Tooltip, ListItemIcon, ListItemText, IconButton, Box } from "@mui/material";

export const SideMenuOption = ({ tooltip, label, onClick, children, selected }) => {
  const onClickHandler = () => {
    onClick();
  };

  return (
    <Tooltip title={tooltip}>
      <Box>
        <IconButton onClick={onClickHandler} sx={{ display: { xs: "block", md: "none" } }}>
          {children}
        </IconButton>
        <ListItemButton onClick={onClickHandler} selected={selected} sx={{ display: { xs: "none", md: "flex" } }}>
          <ListItemIcon>{children}</ListItemIcon>
          <ListItemText primary={label} />
        </ListItemButton>
      </Box>
    </Tooltip>
  );
};

import React from "react";
import { Alert, CircularProgress } from "@mui/material";
import { usePublisherAndContacts, useUpdatePublisherGamev2 } from "@seabrookstudios/pitch2table";
import { EditableListItemUrl } from "../../core/EditableListItemUrl";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisherGame
 */
export const ExternalInventorRelationsLinks = ({ publisherGame }) => {
  const updatePublisherGame = useUpdatePublisherGamev2(publisherGame.id);
  const { data, isLoading } = usePublisherAndContacts(publisherGame.publisherId);

  /**
   * @param {import("@seabrookstudios/types").Url} externalPortalUrl
   */
  const updateExternalPortalLink = (externalPortalUrl) => {
    updatePublisherGame.mutate({
      externalPortalUrl,
      sizzle: undefined,
    });
  };

  if (publisherGame.status === "ShortListed") {
    return null;
  }

  if (isLoading) {
    return <CircularProgress size={20} />;
  }

  if (!data) {
    return null;
  }

  const publisher = data[0];
  if (!publisher) {
    return null;
  }

  if (!publisher.requiresExternalPortal) {
    return null;
  }

  return (
    <>
      {!publisherGame.externalPortalUrl && (
        <Alert severity="error">
          {publisherGame.publisherName} has their own inventor relations portal. To have submitted a game with{" "}
          {publisherGame.publisherName} you should have a link to your game on their portal. Without this, you shouldn't
          consider your game pitched. You can still track your submission and set reminders using pitch2table. There is
          a space below to store the link to your game submission on their portal.
        </Alert>
      )}
      <EditableListItemUrl
        helperText="Where possible link to your submission on their portal rather than the portal itself. It'll make it easier to find your game in the future."
        onSave={updateExternalPortalLink}
        text={publisherGame.externalPortalUrl}
        title="External Inventor Relations Portal Link"
      />
    </>
  );
};

import React from "react";
import { FormControl, FormHelperText, InputLabel, MenuItem, OutlinedInput, Select } from "@mui/material";
import { PublisherGameStatusDescription, PublisherGameStatusLabel } from "@seabrookstudios/pitch2table-core";

const Label = "Status";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherGameStatus} props.value
 * @param {Function} props.onChange
 * @param {boolean} [props.disabled]
 */
export const PublisherInterestStatusSelect = ({ value, onChange, disabled = false }) => {
  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="publisher-status">{Label}</InputLabel>
      <Select
        labelId="publisher-status"
        value={value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
      >
        <MenuItem value="ShortListed">{PublisherGameStatusLabel.ShortListed}</MenuItem>
        <MenuItem value="PitchSent">{PublisherGameStatusLabel.PitchSent}</MenuItem>
        <MenuItem value="PitchReceived">{PublisherGameStatusLabel.PitchReceived}</MenuItem>
        <MenuItem value="InProgress">{PublisherGameStatusLabel.InProgress}</MenuItem>
        <MenuItem value="MaterialsRequested">{PublisherGameStatusLabel.MaterialsRequested}</MenuItem>
        <MenuItem value="UnderConsideration">{PublisherGameStatusLabel.UnderConsideration}</MenuItem>
        <MenuItem value="InContractNegotiation">{PublisherGameStatusLabel.InContractNegotiation}</MenuItem>
        <MenuItem value="Signed">{PublisherGameStatusLabel.Signed}</MenuItem>
        <MenuItem value="Withdrawn">{PublisherGameStatusLabel.Withdrawn}</MenuItem>
        <MenuItem value="Declined">{PublisherGameStatusLabel.Declined}</MenuItem>
      </Select>
      <FormHelperText>{PublisherGameStatusDescription[value] || ""}</FormHelperText>
    </FormControl>
  );
};

import React from "react";
import { Box, Stack, Tooltip, Typography } from "@mui/material";
import { EditTextFieldDialog } from "../core/EditTextFieldDialog";

const EditableTextDisplay = ({ value }) => (
  <Typography variant="body1" textAlign="left" color="text.secondary">
    {value}
  </Typography>
);

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} props.text
 * @param {string} props.helperText
 * @param {boolean} [props.disabled]
 * @param {(text: string) => void} props.onSave
 */
export const EditableText = ({ title, text, helperText, onSave, disabled }) => {
  return (
    <Stack direction="row" display="flex">
      {disabled ? (
        <EditableTextDisplay value={text} />
      ) : (
        <Tooltip title="Click to edit">
          <Box>
            <EditTextFieldDialog
              onSave={onSave}
              title={title}
              initialValue={text}
              isBusy={false}
              helperText={helperText}
            >
              <EditableTextDisplay value={text} />
            </EditTextFieldDialog>
          </Box>
        </Tooltip>
      )}
    </Stack>
  );
};

import React from "react";
import { Box, Typography } from "@mui/material";

/**
 * @param {object} props
 * @param {import("react").ReactNode} [props.children]
 */
export const NoItemsFound = ({ children }) => (
  <Box display="flex" justifyContent="center" alignItems="center" p={2}>
    <Typography align="center">{children}</Typography>
  </Box>
);

import React from "react";
import { usePublisherAndContacts } from "@seabrookstudios/pitch2table";
import { Box, Card, CircularProgress, List, ListItem, ListItemText, Typography } from "@mui/material";
import { PublisherLogo } from "../publisher/PublisherLogo";
import { usePublisherIdFromRoute } from "../games/WithGameIdFromRoute";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { StaticListItemUrl } from "../core/EditableListItemUrl";
import { MySubmissionsWithPublisher } from "./MySubmissionsWithPublisher";
import { MyDocumentsWithPublisher } from "./MyDocumentsWithPublisher";

const MyPublisherDetail = () => {
  const publisherId = usePublisherIdFromRoute();

  const { data: publisherAndContacts = [], isLoading } = usePublisherAndContacts(publisherId);

  const [publisher] = publisherAndContacts;

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <CircularProgress />
      </Card>
    );
  }

  if (!publisher) {
    return <EmptyDetailCard text="An error occurred loading publisher" />;
  }

  return (
    <>
      <Card elevation={1} square={false}>
        <Box p={2}>
          <PublisherLogo url={publisher.logoUrl} name={publisher.name} />
          <Box pt={2}>
            <Typography variant="h5" textAlign="left" color="text.secondary" noWrap>
              {publisher.name}
            </Typography>
          </Box>
          <List>
            {publisher.alternateNames.length > 0 && (
              <ListItem>
                <ListItemText title="Also Known As" secondary={publisher.alternateNames.join(", ")} />
              </ListItem>
            )}
            <StaticListItemUrl title="Website" text={publisher.website} />
            <StaticListItemUrl title="BGG" text={publisher.bgg} />
          </List>
        </Box>
      </Card>
      <Box pt={1} />
      <MySubmissionsWithPublisher publisherId={publisherId} />
      <Box pt={1} />
      <MyDocumentsWithPublisher publisherId={publisherId} />
    </>
  );
};

export const MyPublisherDetailFromRoute = () => {
  const publisherId = usePublisherIdFromRoute();

  if (!publisherId) {
    return <EmptyDetailCard text="Select a publisher from the list on the left." />;
  }

  return <MyPublisherDetail />;
};

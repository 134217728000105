import React from "react";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { UploadSellsheetComponent } from "./UploadSellsheetComponent";

export default function AddSellsheetFullScreenDialog({ gameId }) {
  return (
    <FullScreenDialog fullscreen={false} title="Add Sellsheet" canSave={true}>
      <UploadSellsheetComponent gameId={gameId} />
    </FullScreenDialog>
  );
}

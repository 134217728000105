import React from "react";
import { DateTime } from "luxon";
import { ListView } from "../core/ListView";
import { Alert, ListItemButton, ListItemText } from "@mui/material";
import { NoItemsFound } from "../publisher/NoItemsFound";
import AddSellsheetFullScreenDialog from "./AddSellsheetFullScreenDialog";
import { useNavigate, useParams } from "react-router-dom";
import { Path } from "../Path";
import { sellsheetVersion } from "../games/model";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").Sellsheet} props.sellsheet
 * @param {string} props.version
 */
const SellsheetListEntry = ({ sellsheet, version }) => {
  const params = useParams();
  const navigate = useNavigate();

  const selected = params.attachmentId === sellsheet.id;

  const onClickHandler = () => {
    navigate(Path.game(sellsheet.gameId).sellsheet(sellsheet.id));
  };

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemText
        primary={version}
        secondary={`Uploaded ${DateTime.fromMillis(sellsheet.uploadedTimestamp).toFormat("h:mm a, DDD")}`}
      />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").Sellsheet[]} props.sellsheets
 * @param {import("react-router-dom").To} props.back
 */
export const SellsheetList = ({ gameId, sellsheets = [], back }) => {
  const Action = () => <AddSellsheetFullScreenDialog gameId={gameId} />;

  return (
    <ListView
      title="Sellsheets"
      Filters={null}
      Action={Action}
      back={back}
      EmptyList={() => (
        <NoItemsFound>
          <Alert severity="info">
            Here you can upload your sellsheets. As you pitch and get feedback from publishers you will evolve your
            sellsheet. In the publisher interest section, you can record what version you have sent to each publisher.
          </Alert>
        </NoItemsFound>
      )}
      shown={sellsheets.length}
    >
      {sellsheets.map((sellsheet, index) => (
        <SellsheetListEntry
          key={sellsheet.id.toString()}
          sellsheet={sellsheet}
          version={sellsheetVersion(sellsheets, index)}
        />
      ))}
    </ListView>
  );
};

import React from "react";
import { DateTime } from "luxon";
import {
  CircularProgress,
  FormControl,
  FormHelperText,
  InputLabel,
  MenuItem,
  OutlinedInput,
  Select,
} from "@mui/material";
import { useGameSellsheets } from "@seabrookstudios/pitch2table";
import { sellsheetVersion } from "../model";
import { AlwaysOnAppLink } from "../../styleguide/AppLink";
import { Path } from "../../Path";

const Label = "Sellsheet Sent";

const formatAttachmentDate = (timestamp) => DateTime.fromMillis(timestamp).toFormat("h:mm a, DDD");

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").AttachmentId|undefined} props.value
 * @param {(newValue: import("@seabrookstudios/pitch2table-core").AttachmentId) => void} props.onChange
 * @param {boolean} [props.disabled]
 */
export const PublisherInterestSellsheetSentSelect = ({ gameId, value, onChange, disabled = false }) => {
  const { data: sellsheets = [], isLoading } = useGameSellsheets(gameId);

  /**
   * @param {*} ev
   */
  const onChangeHandler = (ev) => {
    onChange(ev.target.value);
  };

  if (isLoading) {
    return <CircularProgress />;
  }

  return (
    <FormControl sx={{ width: "100%" }} margin="normal" disabled={disabled}>
      <InputLabel id="publisher-interest-sellsheet-sent">{Label}</InputLabel>
      <Select
        labelId="publisher-interest-sellsheet-sent"
        value={sellsheets.length === 0 ? "no-sellsheet" : value}
        onChange={onChangeHandler}
        placeholder={Label}
        label={Label}
        input={<OutlinedInput label={Label} />}
        disabled={sellsheets.length === 0}
      >
        {sellsheets.map((sellsheet, index) => {
          return (
            <MenuItem value={sellsheet.id}>
              {formatAttachmentDate(sellsheet.uploadedTimestamp)} - {sellsheetVersion(sellsheets, index)}
            </MenuItem>
          );
        })}
        {sellsheets.length === 0 && (
          <MenuItem value="no-sellsheet">Upload at least one sellsheet to use this feature.</MenuItem>
        )}
      </Select>
      <FormHelperText>
        The sellsheet version sent to the publisher.{" "}
        <AlwaysOnAppLink to={Path.game(gameId).sellsheets()}>Upload new sellsheet</AlwaysOnAppLink>.
      </FormHelperText>
    </FormControl>
  );
};

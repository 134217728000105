import React, { useState } from "react";
import { FormControlLabel, FormGroup, ListItemText, Switch } from "@mui/material";

/**
 * @param {object} props
 * @param {boolean} props.initialValue
 * @param {boolean} [props.disabled]
 * @param {(newValue: boolean) => void} props.onUpdate
 * @param {string} props.title
 * @param {string} props.onLabel
 * @param {string} props.offLabel
 * @param {import("react").ReactNode} [props.disabledLabel]
 */
export const EditableSwitch = ({
  initialValue,
  onUpdate,
  disabledLabel = null,
  title,
  onLabel,
  offLabel,
  disabled = false,
}) => {
  const [value, setValue] = useState(initialValue);

  const onChange = async (e) => {
    setValue((x) => !x);
    await onUpdate(e.target.checked);
  };

  const enabledLabel = value ? onLabel : offLabel;

  return (
    <FormGroup>
      <FormControlLabel
        control={<Switch disabled={disabled} checked={value} onChange={onChange} />}
        label={<ListItemText primary={title} secondary={disabled ? disabledLabel : enabledLabel} />}
      />
    </FormGroup>
  );
};

import React from "react";
import { FullScreenDialog } from "../core/FullScreenDialog";
import { UploadRulesComponent } from "./UploadRulesComponent";

export default function AddRulesFullScreenDialog({ gameId }) {
  return (
    <FullScreenDialog fullscreen={false} title="Add Rules" canSave={true}>
      <UploadRulesComponent gameId={gameId} />
    </FullScreenDialog>
  );
}

import React from "react";
import LoginButton from "./login/LoginButton";
import { CenterOnPage } from "./core/CenterOnPage";
import { Card, Box, CardActions, Stack, Typography } from "@mui/material";
import textLogo from "./assets/logo.png";
import { theme } from "./theme";

export const LoggedoutPage = () => {
  return (
    <CenterOnPage>
      <Box p={2} maxWidth="75ch">
        <Card>
          <Box p={2}>
            <Stack direction="row" pb={2} pt={2} justifyContent="center">
              <img src={textLogo} alt="" style={{ width: 100, height: "auto" }} />
            </Stack>
            <Typography variant="h3" textAlign="center">
              Taking your games from <span style={{ color: theme.palette.primary.main }}>pitch</span> to the&nbsp;
              <span style={{ color: theme.palette.primary.main }}>table</span>.
            </Typography>
            <Box pt={2}>
              <Typography>
                Whether you're a designer, publisher or self-publisher, Pitch2Table is here to help you take your games
                to the table.
              </Typography>
            </Box>
          </Box>
          <CardActions>
            <LoginButton overrideLabel="Login" />
          </CardActions>
        </Card>
      </Box>
    </CenterOnPage>
  );
};

import React from "react";
import { Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";

/**
 * @param {object} props
 * @param {string} props.primary
 * @param {string} props.secondary
 * @param {import("react-router-dom").To} props.to
 */
export const ArrowListItem = ({ primary, secondary, to }) => {
  return (
    <ListItem>
      <ListItemText primary={primary} secondary={secondary} />
      <Link to={to} component={RouterLink}>
        <ListItemIcon>
          <ListItemButton>
            <ArrowForwardOutlined />
          </ListItemButton>
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};

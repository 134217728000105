import React, { useContext } from "react";
import { useNavigate } from "react-router-dom";
import { CachedTokenContext } from "@seabrookstudios/auth";
import { LoggedoutPage } from "../LoggedOutPage";

export const LoginPage = () => {
  const token = useContext(CachedTokenContext);
  const navigate = useNavigate();

  if (token !== "") {
    navigate("/");
    return;
  }

  return <LoggedoutPage />;
};

import React from "react";
import { CenterOnPage } from "../core/CenterOnPage";
import { Box, Card, Typography } from "@mui/material";
import { useLocation, useNavigate } from "react-router-dom";
import { InstallMobile } from "@mui/icons-material";
import { SideMenuOption } from "../core/SideMenuOption";
import { DiscordLink, SupportEmail } from "../core/CommonLinks";

export const GoMobileButton = () => {
  const navigate = useNavigate();
  const location = useLocation();

  const onClickHandler = () => {
    navigate("/mobile");
  };

  return (
    <SideMenuOption
      tooltip="How to get the iOS or Android app"
      onClick={onClickHandler}
      selected={location.pathname.startsWith("/mobile")}
      label="Mobile"
    >
      <InstallMobile />
    </SideMenuOption>
  );
};

export const GetMobilePage = () => {
  return (
    <CenterOnPage>
      <Card>
        <Box style={{ width: "60vw", height: "60vh" }} p={2}>
          <Box pb={2}>
            <Typography textAlign="center" variant="h4">
              Get Pitch2Table on Mobile
            </Typography>
          </Box>
          <Typography>
            iOS and Android versions of Pitch2Table are in closed beta. Please contact us at:{" "}
            <SupportEmail subject="Pitch2Table App Beta" /> to get access. When you do, us let us know whether you want
            iOS or Android.
          </Typography>
          <Box pt={2}>
            <Typography variant="h6">Android</Typography>
            <Typography>
              For android please supply your Google Play email address as google requires this for us to add your to the
              test group.
            </Typography>
          </Box>
          <Box pt={2}>
            <Typography pt={1} variant="h6">
              iOS
            </Typography>
            <Typography>
              You can use this link:{" "}
              <a href="https://testflight.apple.com/join/DQVZRTbU" target="_blank" rel="noreferrer">
                https://testflight.apple.com/join/DQVZRTbU
              </a>{" "}
              to accept the beta. If there are no spots left, please email{" "}
              <SupportEmail subject="Pitch2Table App Beta" /> or follow the instructions on our <DiscordLink />. You
              will need to install the app via Apple's TestFlight app
            </Typography>
          </Box>
        </Box>
      </Card>
    </CenterOnPage>
  );
};

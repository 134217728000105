import NotificationImportant from "@mui/icons-material/NotificationImportant";
import { showAlarm } from "@seabrookstudios/pitch2table-core";

/**
 * @type {import('@seabrookstudios/pitch2table-core').PublisherGameStatus[]}
 */
const ShortListed = ["ShortListed"];

/**
 * @type {import('@seabrookstudios/pitch2table-core').PublisherGameStatus[]}
 */
const Sent = ["PitchReceived", "PitchSent"];

/**
 * @type {import('@seabrookstudios/pitch2table-core').PublisherGameStatus[]}
 */
const InProgress = ["InProgress", "MaterialsRequested", "InContractNegotiation", "UnderConsideration"];

/**
 * @type {Filter<import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView>[]}
 */
export const PublisherListFilters = [
  {
    filter: (game) => showAlarm(game.nextAction),
    Icon: NotificationImportant,
    label: "DUE",
    count: 0,
    weight: 1000,
  },
  {
    filter: (game) => InProgress.includes(game.status),
    label: "IN PROGRESS",
    count: 0,
    weight: 100,
  },
  {
    filter: (game) => Sent.includes(game.status),
    label: "SENT",
    count: 0,
    weight: 50,
  },
  {
    filter: (game) => ShortListed.includes(game.status),
    label: "SHORTLISTED",
    count: 0,
    weight: 10,
  },
  {
    filter: (game) => !!game,
    label: "ALL",
    count: 0,
    weight: 0,
  },
];

import React from "react";
import {
  Box,
  Card,
  CircularProgress,
  IconButton,
  List,
  ListItem,
  ListItemAvatar,
  ListItemText,
  Paper,
  Typography,
} from "@mui/material";
import { useAcceptInvite, useDeclineInvite, useInviteDetails, useInvitesForUser } from "@seabrookstudios/pitch2table";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { Check, Delete } from "@mui/icons-material";
import { UserName } from "../users/UserName";
import { UserAvatar } from "../users/UserAvatar";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Invite} props.invite
 */
const InvitationsListItem = ({ invite }) => {
  const { data: details, isLoading } = useInviteDetails(invite.id);
  const acceptInvite = useAcceptInvite();
  const declineInvite = useDeclineInvite();

  const acceptInviteHandler = () => {
    acceptInvite.mutate({ inviteId: invite.id });
  };

  const declineInviteHandler = () => {
    declineInvite.mutate({ inviteId: invite.id });
  };

  if (isLoading) {
    return <CircularProgress size={16} />;
  }

  return (
    <ListItem
      secondaryAction={
        <IconButton edge="end" aria-label="delete" onClick={declineInviteHandler}>
          <Delete />
        </IconButton>
      }
    >
      <ListItemAvatar>
        <UserAvatar userId={invite.invitor} />
      </ListItemAvatar>
      <ListItemText
        primary={details ? details.name : "Error"}
        secondary={
          <Typography component="span" variant="inherit">
            Invited by <UserName userId={invite.invitor} />
          </Typography>
        }
      />
      <IconButton aria-label="accept" onClick={acceptInviteHandler}>
        <Check />
      </IconButton>
    </ListItem>
  );
};

export const InvitationsList = () => {
  const { data: invites = [], isFetching } = useInvitesForUser();

  if (isFetching) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (invites.length === 0) {
    return (
      <Paper elevation={1} square={false}>
        <NoItemsFound>No invites found.</NoItemsFound>
      </Paper>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        {invites.map((invite) => (
          <InvitationsListItem key={invite.id} invite={invite} />
        ))}
      </List>
    </Card>
  );
};

/**
 * @param {string} playerCount
 * @returns {string}
 */
export const formatPlayerCount = (playerCount) => (playerCount ? playerCount.split("-").join(" - ") : "not set");

/**
 * @param {string} duration
 * @returns {string}
 */
export const formatDuration = (duration) => (duration ? duration : "not set");

/**
 * @param {number} age
 * @returns {string}
 */
export const formatAge = (age) => (age >= 0 ? `${age}+` : "not set");

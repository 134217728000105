const PublicApi = "https://public.pitch2table.com";
const PrivateApi = "https://secure.pitch2table.com";

const endpoints = () => {
  const publicApi = PublicApi;
  const privateApi = PrivateApi;

  const server = () => {
    return {
      config: () => {
        return `${publicApi}/config`;
      },
    };
  };

  const users = () => {
    const server = privateApi;

    return {
      me: () => {
        return `${server}/v1/users/me`;
      },
      meNew: () => {
        return `${server}/users/me`;
      },
      followUp: () => {
        return `${server}/users/follow-up`;
      },
      avatar: () => {
        return `${server}/v1/users/me/avatar`;
      },
      seed: () => {
        return `${server}/v1/users/seed`;
      },
    };
  };

  const publishers = () => {
    const server = privateApi;

    return {
      me: () => {
        return `${server}/v1/publishers/me`;
      },
      new: () => {
        return `${server}/v1/publishers`;
      },
      /**
       * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
       * @returns
       */
      info: (publisherId) => {
        return `${server}/v1/publishers/${publisherId}`;
      },
      /**
       * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
       * @returns
       */
      contact: (publisherId) => {
        return `${server}/v1/publishers/${publisherId}/contact`;
      },
      /**
       * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
       * @returns
       */
      logo: (publisherId) => {
        return `${server}/v1/publishers/${publisherId}/logo`;
      },
      /**
       * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
       * @returns
       */
      submissions: (publisherId) => {
        return `${server}/v1/publishers/${publisherId}/submissions`;
      },
      /**
       * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
       * @returns
       */
      game: (publisherId, gameId) => {
        return `${server}/v1/publishers/${publisherId}/games/${gameId}`;
      },
    };
  };

  const contacts = () => {
    const server = privateApi;

    return {
      get: (userId) => {
        return `${server}/v1/contacts/${userId}`;
      },
    };
  };

  const games = () => {
    const server = privateApi;

    return {
      create: () => {
        return `${server}/v1/games`;
      },
      get: () => {
        return `${server}/v1/games`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      game: (gameId) => {
        return `${server}/v1/games/${gameId}`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      sellSheet: (gameId) => {
        return `${server}/v1/games/${gameId}/sellsheet`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      cover: (gameId) => {
        return `${server}/v1/games/${gameId}/cover`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      sellsheets: (gameId) => {
        return `${server}/v1/games/${gameId}/sellsheets`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      contracts: (gameId) => {
        return `${server}/v1/games/${gameId}/contracts`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      rules: (gameId) => {
        return `${server}/v1/games/${gameId}/rules`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      publisherInterest: (gameId) => {
        return `${server}/v1/games/${gameId}/publishers`;
      },
    };
  };

  const publicGames = () => {
    const server = publicApi;

    return {
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      game: (gameId) => {
        return `${server}/v1/games/${gameId}`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      sellsheets: (gameId) => {
        return `${server}/v1/games/${gameId}/sellsheets`;
      },
      /**
       * @param {import("@seabrookstudios/types").GameId} gameId
       * @returns
       */
      rules: (gameId) => {
        return `${server}/v1/games/${gameId}/rules`;
      },
    };
  };

  return {
    contacts,
    games,
    publicGames,
    publishers,
    server,
    users,
  };
};

/**
 * @param {string} s3Location
 * @returns
 */
export const assetPath = (s3Location) => `https://assets.pitch2table.com/${s3Location}`;

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 */
export const uploadContractToPublisher = (publisherId) => {
  return `${PrivateApi}/contact-book/publishers/${publisherId}/contracts`;
};

export default endpoints;

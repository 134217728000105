import React from "react";
import { useGame, useMyDocuments, usePublisherAndContacts } from "@seabrookstudios/pitch2table";
import { idExtractor, useMakeListAndDetail, useMakeListBuilder } from "../core/useMakeLayout";
import { NoItemsFound } from "../publisher/NoItemsFound";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import { Alert, ListItemAvatar, ListItemButton, ListItemText, Skeleton } from "@mui/material";
import { PublisherLogoSquare2 } from "../publisher/PublisherLogo";
import { useAttachmentIdFromRoute } from "../games/WithGameIdFromRoute";
import { formatFullDate } from "@seabrookstudios/pitch2table-core";
import { CoverSquare } from "../games/CoverImages";
import { MyDocumentsDetailFromRoute } from "./DocumentsDetailView";

const NotFound = () => (
  <NoItemsFound>
    <Alert severity="info">
      When you upload contracts against publishers (e.g. an NDA) or for a game (e.g. a contract), they will appear here.
    </Alert>
  </NoItemsFound>
);

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").DocumentListEntry} props.item
 */
export const GameContractListEntry = ({ item }) => {
  const attachmentId = useAttachmentIdFromRoute();
  const { data: game } = useGame(/** @type {import("@seabrookstudios/types").GameId} */ (item.refId));
  const navigate = useNavigate();

  const selected = attachmentId === item.id;

  const onClickHandler = () => {
    navigate(Path.document(item.id));
  };

  if (!game) {
    return (
      <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
        <ListItemAvatar>
          <Skeleton width={48} height={48} />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
      </ListItemButton>
    );
  }

  const secondary = !!item.signedDate
    ? `Signed: ${formatFullDate(item.signedDate)}`
    : `Created ${formatFullDate(item.created)}`;

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <CoverSquare game={game} width={48} height={48} />
      </ListItemAvatar>
      <ListItemText primary={`${game.name} - ${item.name}`} secondary={secondary} />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").DocumentListEntry} props.item
 */
export const PublisherContractListEntry = ({ item }) => {
  const attachmentId = useAttachmentIdFromRoute();
  const { data: publisherAndContacts = [] } = usePublisherAndContacts(
    /** @type {import("@seabrookstudios/pitch2table-core").PublisherId} */ (item.refId)
  );
  const navigate = useNavigate();

  const selected = attachmentId === item.id;

  const onClickHandler = () => {
    navigate(Path.document(item.id));
  };

  const [publisher] = publisherAndContacts;

  if (!publisher) {
    return (
      <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
        <ListItemAvatar>
          <Skeleton width={48} height={48} />
        </ListItemAvatar>
        <ListItemText primary={<Skeleton />} secondary={<Skeleton />} />
      </ListItemButton>
    );
  }

  return (
    <ListItemButton alignItems="flex-start" selected={selected} onClick={onClickHandler}>
      <ListItemAvatar>
        <PublisherLogoSquare2 publisher={publisher} width={48} height={48} />
      </ListItemAvatar>
      <ListItemText
        primary={`${publisher.name} - ${item.name}`}
        secondary={`Signed: ${formatFullDate(item.signedDate)}`}
      />
    </ListItemButton>
  );
};

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").DocumentListEntry} props.item
 */
export const MyDocumentListEntry = ({ item }) => {
  if (item.refType === "GameId") {
    return <GameContractListEntry item={item} />;
  }

  return <PublisherContractListEntry item={item} />;
};

export const DocumentsListAndDetail = () => {
  const useMakeList = useMakeListBuilder(MyDocumentListEntry, idExtractor, "My Contracts", NotFound);

  const ListAndDetail = useMakeListAndDetail(useMyDocuments, [], "contracts", useMakeList, MyDocumentsDetailFromRoute);

  return <ListAndDetail />;
};

import React from "react";
import { Box, Card, Chip, Link, List, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ArrowForwardOutlined, Check, Warning } from "@mui/icons-material";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../../Path";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").Game} props.game
 */
export const ContractCardForGame = ({ game }) => {
  return (
    <Card elevation={1} square={false}>
      <List>
        <ListItem>
          <ListItemText
            primary="Contracts"
            secondary={
              game.hasContracts ? "Uploaded" : "No contract uploaded. It's not signed unless there is a contract."
            }
          />
          <ListItemIcon>
            <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
              {game.hasContracts ? (
                <Chip icon={<Check />} label="DONE" color="success" />
              ) : (
                <Chip icon={<Warning />} label="MISSING" color="warning" />
              )}
            </Box>
          </ListItemIcon>
          <Link to={Path.game(game.id).contracts()} component={RouterLink}>
            <ListItemIcon>
              <ListItemButton>
                <ArrowForwardOutlined />
              </ListItemButton>
            </ListItemIcon>
          </Link>
        </ListItem>
      </List>
    </Card>
  );
};

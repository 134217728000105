import { useQuery } from "@tanstack/react-query";
import { PublicGameByGameId } from "./query-keys";
import { fetchPublicGame } from "./games-service";
import { useContacts } from "@seabrookstudios/pitch2table";

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const usePublicGame = (gameId) => {
  return useQuery({
    queryKey: PublicGameByGameId(gameId),
    queryFn: async () => {
      return fetchPublicGame(gameId);
    },
    staleTime: 0,
  });
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").GameDesigners[]} designers
 */
export const useDesigners = (designers = []) => {
  const userIds = designers.map(({ designerId }) => designerId);
  return useContacts(userIds);
};

import React from "react";
import { ListDetail } from "../web/ListDetail";
import { GameDetailFromRoute } from "./GameDetail";
import { GamesList } from "./GamesList";
import { useMyGames } from "@seabrookstudios/pitch2table";
import { GamesListFilters } from "./filters";
import { useFilters } from "../core/useFilters";

export const GamesListAndDetail = () => {
  const { data: games = [], refetch, isLoading, isRefetching } = useMyGames();
  const { filtered, filter, visibleFilters, onFilterClicked } = useFilters(games, GamesListFilters, "games");

  const ListView = () => (
    <GamesList
      filter={filter}
      filters={visibleFilters}
      onFilterClicked={onFilterClicked}
      summaries={filtered}
      refetch={refetch}
      isLoading={isLoading}
      isRefetching={isRefetching}
    />
  );

  return <ListDetail ListView={ListView} DetailView={GameDetailFromRoute} />;
};

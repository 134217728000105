import NotificationImportant from "@mui/icons-material/NotificationImportant";
import { showAlarm } from "@seabrookstudios/pitch2table-core";

/**
 * @type {Filter<import("@seabrookstudios/pitch2table-core").GameSummary>[]}
 */
export const GamesListFilters = [
  {
    filter: (game) => (!game.nextAction ? false : showAlarm(game.nextAction)),
    Icon: NotificationImportant,
    label: "DUE",
    count: 0,
    weight: 1000,
  },
  { filter: (game) => ["PitchingNow"].includes(game.status), label: "PITCHING", count: 0, weight: 100 },
  { filter: (game) => ["Signed", "Published"].includes(game.status), label: "SIGNED", count: 0, weight: 50 },
  {
    filter: (game) => ["InDevelopmentEarly", "InDevelopmentMid", "InDevelopmentLate"].includes(game.status),
    label: "DESIGNING",
    count: 0,
    weight: 10,
  },
  { filter: (game) => !!game, label: "ALL", count: 0, weight: 0 },
];

import axios from "axios";
import axiosRetry from "axios-retry";

const exponentialBackoff = (retryCount) => {
  return 2 ** retryCount * 1000 + Math.floor(Math.random() * 1000);
};

axiosRetry(axios, { retries: 5, retryDelay: exponentialBackoff });

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @returns
 */
export const optionsAuth = (token) => {
  return {
    headers: {
      Authorization: `Bearer ${token}`,
      Accept: "application/json",
    },
  };
};

export const optionsNoAuth = () => {
  return {
    headers: {
      Accept: "application/json",
    },
  };
};

export const get = (url, config) => {
  return axios.get(url, config);
};

export const post = (url, data, config) => {
  return axios.post(url, data, config);
};

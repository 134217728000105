import React from "react";
import { ListDetail } from "../../web/ListDetail";
import { List } from "./List";
import { Path } from "../../Path";
import { useGameContracts } from "@seabrookstudios/pitch2table";
import { useGameIdFromRoute } from "../WithGameIdFromRoute";
import { ContractFromRoute } from "../../sellsheets/ContractFromRoute";

export const ListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data: contracts = [], refetch } = useGameContracts(gameId);

  const SelectedSellsheet = () => <ContractFromRoute />;

  const ListView = () => (
    <List gameId={gameId} contracts={contracts} back={Path.game(gameId).link()} refetch={refetch} />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedSellsheet} />;
};

import React from "react";
import {
  Box,
  Button,
  Card,
  CardActions,
  CardContent,
  CardHeader,
  Chip,
  Container,
  Grid,
  Stack,
  Typography,
} from "@mui/material";
import { Path } from "../Path";
import { useGame, useMyGames } from "@seabrookstudios/pitch2table";
import { GameCardCover } from "../games/CoverImages";
import { formatPlayerCount } from "../games/formatters";

const GameCard = ({ game }) => {
  const { data: fullGame } = useGame(game.id);

  return (
    <Card>
      <CardHeader
        title={game.name}
        subheader={<Chip variant="filled" label={formatPlayerCount(fullGame?.playerCount)} />}
      ></CardHeader>
      <GameCardCover game={game} editable={false} />
      <CardContent>{game.tag}</CardContent>
      <CardActions>
        <Button>LEARN MORE</Button>
        {fullGame && fullGame.bgg && <Button>BGG</Button>}
      </CardActions>
    </Card>
  );
};

const PublicDesigner = () => {
  const { data: games = [] } = useMyGames();

  return (
    <Container>
      <Typography variant="h3">Ryan Boucher</Typography>
      <Typography variant="h4">Designer</Typography>
      <Typography variant="body1">Melbourne, Australia (4:22pm)</Typography>
      <Stack direction="column">
        <Typography variant="h5">Pitching Now</Typography>
        <Typography variant="body2">Only games the designer has set to public will be shown here.</Typography>
        <Grid container spacing={0}>
          {games
            .filter((game) => game.status === "PitchingNow")
            .map((game) => {
              return (
                <Grid key={game.id} xs={12} sm={6} md={4}>
                  <Box p={1}>
                    <GameCard game={game} />
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Stack>
      <Stack direction="column">
        <Typography variant="h5">Published Games</Typography>
        <Typography variant="body2">Only games the designer has set to public will be shown here.</Typography>
        <Grid container spacing={0}>
          {games
            .filter((game) => game.status === "Published")
            .map((game) => {
              return (
                <Grid key={game.id} xs={12} sm={6} md={4}>
                  <Box p={1}>
                    <GameCard game={game} />
                  </Box>
                </Grid>
              );
            })}
        </Grid>
      </Stack>
    </Container>
  );
};

export const routes = [
  {
    path: Path.public().designer(":username").pathname,
    element: <PublicDesigner />,
  },
];

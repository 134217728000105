import { useQuery } from "@tanstack/react-query";
import {
  fetchMyPublishers,
  fetchPublisher,
  fetchPublisherContact,
  fetchPublisherGame,
  fetchSubmissions,
} from "./service";
import { DefaultStaleTime } from "../core/react-query";
import {
  MyPublishers,
  PublisherByPublisherId,
  PublisherGameByGameId,
  PublisherSubmissionsByPublisherId,
} from "../games/query-keys";
import { useContext } from "react";
import { CachedTokenContext } from "@seabrookstudios/auth";

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId?} [publisherId]
 */
export const PublisherContactKey = (publisherId) => ["publisher-contact", publisherId];

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 */
export const usePublisher = (publisherId) => {
  const token = useContext(CachedTokenContext);

  return useQuery({
    queryKey: PublisherByPublisherId(publisherId),
    queryFn: async () => {
      return fetchPublisher(token, publisherId);
    },
  });
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId?} [publisherId]
 */
export const usePublisherSubmissions = (publisherId) => {
  const token = useContext(CachedTokenContext);

  return useQuery({
    queryKey: PublisherSubmissionsByPublisherId(publisherId),
    queryFn: async () => {
      if (!publisherId) {
        return [];
      }

      const games = await fetchSubmissions(token, publisherId);

      return games;
    },
    staleTime: DefaultStaleTime,
  });
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const usePublisherGame = (publisherId, gameId) => {
  const token = useContext(CachedTokenContext);

  return useQuery({
    queryKey: PublisherGameByGameId(publisherId, gameId),
    queryFn: async () => {
      const data = await fetchPublisherGame(token, publisherId, gameId);
      return data;
    },
    staleTime: DefaultStaleTime,
  });
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} publisherId
 */
export const usePublisherContact = (publisherId) => {
  const token = useContext(CachedTokenContext);

  return useQuery({
    queryKey: PublisherContactKey(publisherId),
    queryFn: async () => {
      const data = await fetchPublisherContact(token, publisherId);
      return data;
    },
    staleTime: DefaultStaleTime,
  });
};

export const useMyPublishers = () => {
  const token = useContext(CachedTokenContext);

  return useQuery({
    queryKey: MyPublishers(),
    queryFn: async () => {
      return fetchMyPublishers(token);
    },
    staleTime: DefaultStaleTime,
  });
};

import React, { useContext, useEffect } from "react";
import { useNavigate } from "react-router-dom";
import { CachedTokenContext, s2Token } from "@seabrookstudios/auth";
import { LoggedoutPage } from "./LoggedOutPage";
import { useAuth0 } from "@auth0/auth0-react";

/**
 * @param {object} props
 * @param {(() => React.JSX.Element)|string|null} props.UnauthenticatedView
 * @param {import("react").ReactNode} [props.children]
 */
function ProtectedRoute({ children, UnauthenticatedView = null }) {
  const token = useContext(CachedTokenContext);
  const { isLoading } = useAuth0();
  const navigate = useNavigate();
  const ToShow = UnauthenticatedView ? UnauthenticatedView : LoggedoutPage;

  const isAuthenticated = token !== s2Token("");

  const isRedirect = !isAuthenticated && typeof UnauthenticatedView === "string";
  useEffect(() => {
    if (isLoading) {
      return;
    }
    if (isRedirect) {
      navigate(UnauthenticatedView);
      return;
    }
  }, [isRedirect, navigate, UnauthenticatedView, isLoading]);

  if (isRedirect) {
    return null;
  }

  return <>{isAuthenticated ? children : <ToShow />}</>;
}

export default ProtectedRoute;

import React, { useRef } from "react";
import { Box, Icon, Stack, Typography } from "@mui/material";
import { EditOutlined } from "@mui/icons-material";
import { useHover } from "usehooks-ts";

const EditableTagDisplay = ({ value }) => (
  <Typography variant="body1" fontStyle="italic" textAlign="left" color="text.secondary">
    {value}
  </Typography>
);

/**
 * @param {object} props
 * @param {string} props.text
 * @param {Function?} [props.onSave]
 * @param {boolean} [props.disabled]
 * @returns {React.JSX.Element}
 */
export const EditableTag = ({ text, onSave, disabled }) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Stack direction="row">
      <Box ref={hoverRef}>
        <Typography variant="body1" fontStyle="italic" textAlign="left" color="text.secondary">
          {text}
        </Typography>
      </Box>
      <Box pl={1} display="flex" justifyContent="center" alignItems="center">
        {isHover && !disabled ? <EditOutlined fontSize="small" /> : <Icon fontSize="small" />}
      </Box>
    </Stack>
  );
};

import React, { useEffect, useState } from "react";
import { ListDetail } from "../../web/ListDetail";
import { CircularProgress, Typography } from "@mui/material";
import { useGameIdFromRoute } from "../../games/WithGameIdFromRoute";
import { Path } from "../../Path";
import { DesignerList } from "./DesignerList";
import { GameDesignerFromRoute } from "./DesignerDetail";
import { useGame, useInvitesForGame } from "@seabrookstudios/pitch2table";

/**
 * @param {import("@seabrookstudios/pitch2table-core").GameDesigners} designer
 * @returns {GameDesignersIncludingPending}
 */
const fromDesigner = (designer) => ({
  id: designer.designerId,
  role: designer.role,
  pending: false,
});

/**
 * @param {import("@seabrookstudios/pitch2table-core").Invite} invite
 * @returns {GameDesignersIncludingPending}
 */
const fromInvite = (invite) => ({
  id: invite.invitee,
  role: "codesigner",
  pending: true,
});

export const DesignerListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data: game, isFetching: isFetchingGame } = useGame(gameId);
  const { data: invites = [] } = useInvitesForGame(gameId);
  const [designers, setDesigners] = useState(/** @type {GameDesignersIncludingPending[]} */ ([]));

  const SelectedDesigner = () => <GameDesignerFromRoute />;

  useEffect(() => {
    const current = game ? game.designers.map(fromDesigner) : [];
    const pending = invites ? invites.map(fromInvite) : [];

    setDesigners([...current, ...pending]);
  }, [game, invites]);

  if (isFetchingGame) {
    return <CircularProgress />;
  }

  if (!game) {
    return <Typography>Error</Typography>;
  }

  const ListView = () => <DesignerList gameId={gameId} designers={designers} back={Path.game(gameId).link()} />;

  return <ListDetail ListView={ListView} DetailView={SelectedDesigner} />;
};

import React from "react";
import { AppBar, Box, Card, Toolbar, Typography, Stack } from "@mui/material";
import { SectionHeading } from "../core/font-sizes";
import LoginButton from "../login/LoginButton";

export const AddToSubmissionsLandingPageLoggedIn = () => {
  return (
    <>
      <AppBar position="static">
        <Toolbar>
          <Typography variant="h6" component="div" sx={{ flexGrow: 1 }}>
            THE QUOLL
          </Typography>
          <LoginButton overrideLabel="TRY IT FREE" />
        </Toolbar>
      </AppBar>
      <Box pt={2}>
        <Card>
          <Box p={2}>
            <Typography variant="h6">
              Quoll the only inventor relations platform designed with board games in mind.
            </Typography>
            <SectionHeading>Sign games with less effort</SectionHeading>
            <Typography>
              With a single click One Trick Ponies could be in your submissions queue. With another you could be
              requesting a digital or physical prototype. We'll help you focus more on games and less on admistration.
            </Typography>
            {/* <Typography>
              To protect your business and designers, all publishers accepting submissions are manually approved. Insert
              your email address below and we'll get in touch. Or, if you want to look around first, you create a free
              account and see how else QUOLL can help you.
            </Typography> */}
            {/* <Input></Input> */}
            <Typography>
              Already have an account? Login below and we'll handle the rest. Otherwise, enjoy a free trial (no credit
              card required).
            </Typography>
            <Stack direction="row" pt={2}>
              <Box pr={1}>
                <LoginButton overrideLabel="LOGIN" />
              </Box>
              <LoginButton overrideLabel="TRY IT FREE" />
            </Stack>
          </Box>
        </Card>
      </Box>
    </>
  );
};

import endpoints from "../endpoints";
import { get, optionsAuth, optionsNoAuth } from "../network";

/**
 * @param {import("@seabrookstudios/types").GameId?} [gameId]
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").PublicGame|null>}
 */
export const fetchPublicGame = async (gameId) => {
  if (!gameId) {
    return null;
  }

  const { publicGames } = endpoints();
  const url = publicGames().game(gameId);

  const response = await get(url, optionsNoAuth());
  return response.data;
};

/**
 * @param {import("@seabrookstudios/auth").TokenString} token
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").Sellsheet[]>}
 */
export const fetchSellsheetsForGame = async (token, gameId) => {
  const { games } = endpoints();
  const url = games().sellsheets(gameId);

  const response = await get(url, optionsAuth(token));
  return response.data;
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").Sellsheet[]>}
 */
export const fetchLatestSellsheetForGame = async (gameId) => {
  const { publicGames } = endpoints();
  const url = publicGames().sellsheets(gameId);

  const response = await get(url, optionsNoAuth());
  return response.data;
};

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 * @returns {Promise<import("@seabrookstudios/pitch2table-core").Rules[]>}
 */
export const fetchLatestRulesForGame = async (gameId) => {
  const { publicGames } = endpoints();
  const url = publicGames().rules(gameId);

  const response = await get(url, optionsNoAuth());
  return response.data;
};

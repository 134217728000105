import React, { useCallback } from "react";
import Logout from "@mui/icons-material/Logout";
import { useLogout } from "@seabrookstudios/auth-web";
import { SideMenuOption } from "../core/SideMenuOption";

export const LogoutScreen = () => {
  const logout = useLogout();

  logout();

  return null;
};

const LogoutButton = () => {
  const logout = useLogout();

  const onClickHandler = useCallback(() => logout(), [logout]);

  return (
    <SideMenuOption label="Logout" selected={false} tooltip="Logout of Pitch2Table" onClick={onClickHandler}>
      <Logout />
    </SideMenuOption>
  );
};

export default LogoutButton;

import { DateTime } from "luxon";
import { daysFromNow } from "@seabrookstudios/pitch2table-core";

/**
 * @param {number} timestamp
 * @returns {"info"|"warning"|"error"}
 */
export const getSeverity = (timestamp) => {
  if (timestamp === 0) {
    return "info";
  }
  if (timestamp < DateTime.now().toMillis()) {
    return "error";
  }
  if (daysFromNow(timestamp) <= 0) {
    return "error";
  }
  if (daysFromNow(timestamp) <= 3) {
    return "warning";
  }

  return "info";
};

import React from "react";
import { Box } from "@mui/material";

export const SinglePageView = ({ children }) => {
  return (
    <Box width="100%" flex={1}>
      {children}
    </Box>
  );
};

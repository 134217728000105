import React, { createContext, useState, useMemo } from "react";

/**
 * @param {string} f
 */
const doNothing = (f) => {
  console.log(f);
};

export const ShortlistPublisherFilterContext = createContext({ filter: "", setFilter: doNothing });

/**
 * @param {object} props
 * @param {import("react").ReactNode} props.children
 */
export const ShortlistPublisherFilterProvider = ({ children }) => {
  const [filter, setFilter] = useState("");

  const value = useMemo(
    () => ({
      filter,
      setFilter,
    }),
    [filter, setFilter]
  );

  return <ShortlistPublisherFilterContext.Provider value={value}>{children}</ShortlistPublisherFilterContext.Provider>;
};

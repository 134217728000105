import React from "react";
import { Path } from "../Path";
import { AppWithSideMenu } from "../core/AppWithSideMenu";
import {
  makeBreadcrumbs,
  makeBreadcrumbs2,
  MyDocumentNameBreadCrumb,
  MyPublisherDocuments,
  MyPublisherNameBreadcrumb,
  MyPublishers,
} from "../core/Breadcrumbs";
import { PublishersListAndDetail } from "./ListAndDetail";
import { PublishersDocumentsListAndDetail } from "./PublishersDocumentsListAndDetail";
import { s2AttachmentId, s2PublisherId } from "../util/type-conversion";

export const routes = [
  {
    path: Path.publishers().pathname,
    element: (
      <AppWithSideMenu>
        <PublishersListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("My Publishers", () => Path.publishers(), []),
      prefer: "left",
    },
  },
  {
    path: Path.publisher(s2PublisherId(":publisherId")).link().pathname,
    element: (
      <AppWithSideMenu>
        <PublishersListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs2([MyPublishers], MyPublisherNameBreadcrumb),
      prefer: "right",
    },
  },
  {
    path: Path.publisher(s2PublisherId(":publisherId")).documents().pathname,
    element: (
      <AppWithSideMenu>
        <PublishersDocumentsListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs("Documents", () => Path.publisher(s2PublisherId(":publisherId")).documents(), [
        MyPublishers,
        MyPublisherNameBreadcrumb,
      ]),
      prefer: "left",
    },
  },
  {
    path: Path.publisher(s2PublisherId(":publisherId")).document(s2AttachmentId(":attachmentId")).pathname,
    element: (
      <AppWithSideMenu>
        <PublishersDocumentsListAndDetail />
      </AppWithSideMenu>
    ),
    handle: {
      crumb: makeBreadcrumbs2(
        [MyPublishers, MyPublisherNameBreadcrumb, MyPublisherDocuments],
        MyDocumentNameBreadCrumb
      ),
      prefer: "right",
    },
  },
];

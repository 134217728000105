import React, { useEffect, useState } from "react";
import { DateTime } from "luxon";
import { Box, Button, Card, CircularProgress, IconButton, List, Skeleton, Stack, Typography } from "@mui/material";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { useGameIdFromRoute, usePublisherIdFromRoute } from "./WithGameIdFromRoute";
import TimelineItem, { timelineItemClasses } from "@mui/lab/TimelineItem";
import { Timeline, TimelineConnector, TimelineContent, TimelineDot, TimelineSeparator } from "@mui/lab";
import {
  useDeletePublisherGame,
  useGamePublishers,
  usePublisherGameAsDesigner,
  usePublisherGameUpdate,
  usePublisherGameUpdates,
  useUpdatePublisherGamev2,
} from "@seabrookstudios/pitch2table";
import { PublisherLogo } from "../publisher/PublisherLogo";
import { AddPublisherUpdate } from "./publisher-interest/AddPublisherUpdate";
import { useUserProfile } from "../user/queries";
import { PublisherUpdateOwnerIcon } from "./publisher-interest/PublisherUpdateOwnerIcon";
import { EditPublisherUpdate } from "./publisher-interest/EditPublisherUpdate";
import { Cached, Delete } from "@mui/icons-material";
import { PublisherInterestStatusSelect } from "./publisher-interest/PublisherInterestStatusSelect";
import { FollowUp } from "./publisher-interest/FollowUp";
import { PublisherInterestRulesSentSelect } from "./publisher-interest/PublisherInterestRulesSentSelect";
import { PublisherInterestSellsheetSentSelect } from "./publisher-interest/PublisherInterestSellsheetSentSelect";
import { EditableSwitch } from "../core/EditableSwitch";
import { ExternalInventorRelationsLinks } from "./publisher-interest/ExternalInventorRelationsLinks";
import { useNavigate } from "react-router-dom";
import { Path } from "../Path";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherGameUpdateId} props.updateId
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisherGame
 * @param {import("@seabrookstudios/types").UserId} props.userId
 * @param {boolean} props.notLast
 */
const PublisherUpdate = ({ updateId, notLast, publisherGame, userId }) => {
  const { data: update } = usePublisherGameUpdate(publisherGame.id, updateId);

  if (!update) {
    return <Skeleton />;
  }

  return (
    <TimelineItem>
      <TimelineSeparator>
        <TimelineDot />
        {notLast && <TimelineConnector />}
      </TimelineSeparator>
      <TimelineContent>
        <Stack direction="row" justifyContent="space-between">
          <Stack direction="column" justifyContent="space-between">
            <Typography>
              {DateTime.fromMillis(update.timestamp).toFormat("DDD")}
              <Typography variant="body2" color="text.secondary" component="span">
                {DateTime.fromMillis(update.timestamp).toFormat(" @ TTT")}
              </Typography>{" "}
              <EditPublisherUpdate update={update} publisherGame={publisherGame} userId={userId} />
            </Typography>
            <Typography color="text.secondary" variant="body2" maxWidth="66ch" sx={{ whiteSpace: "pre-wrap" }}>
              {update.update}
            </Typography>
          </Stack>
          <PublisherUpdateOwnerIcon update={update} publisherGame={publisherGame} />
        </Stack>
      </TimelineContent>
    </TimelineItem>
  );
};

export const PublisherInterestInGame = () => {
  const gameId = useGameIdFromRoute();
  const publisherId = usePublisherIdFromRoute();

  const { data: profile } = useUserProfile();
  // const { data: contact } = usePublisherContact(publisherId);
  const { data: publishers = [] } = useGamePublishers(gameId);
  const [publisherGameId, setPublisherGameId] = useState(
    /** @type {import("@seabrookstudios/pitch2table-core").PublisherGameId|null} */ (null)
  );
  const { data: publisherGame } = usePublisherGameAsDesigner(publisherGameId);

  const { data: updates = [], isLoading, refetch, isRefetching } = usePublisherGameUpdates(publisherGameId);
  const updatePublisherGame = useUpdatePublisherGamev2(publisherGame ? publisherGame.id : null);
  const deletePublisherGame = useDeletePublisherGame(gameId, publisherGame ? publisherGame.id : null);
  const navigate = useNavigate();

  useEffect(() => {
    if (!publishers) {
      return;
    }

    const publisherGame = publishers.find((p) => p.publisherId === publisherId && p.gameId === gameId);
    if (!publisherGame) {
      return;
    }

    setPublisherGameId(publisherGame.id);
  }, [publishers, publisherId, gameId]);

  if (!gameId || !publisherId) {
    return <EmptyDetailCard text="Select a publisher from the list on the left." />;
  }

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!publisherGame || !profile) {
    return <EmptyDetailCard text="There was an issue fetching data." />;
  }

  const refetchHandler = () => {
    refetch();
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").PublisherGameStatus} status
   */
  const saveUpdate = async (status) => {
    await updatePublisherGame.mutateAsync({ status });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} rules
   */
  const updateRulesSent = async (rules) => {
    await updatePublisherGame.mutateAsync({ rules });
  };

  /**
   * @param {import("@seabrookstudios/pitch2table-core").AttachmentId} sellsheet
   */
  const updateSellsheetSent = async (sellsheet) => {
    await updatePublisherGame.mutateAsync({ sellsheet });
  };

  /**
   * @param {boolean} sentPNP
   */
  const togglePnpFiles = async (sentPNP) => {
    await updatePublisherGame.mutateAsync({ sentPNP });
  };

  const removeShortlistedPublisher = async () => {
    await deletePublisherGame.mutateAsync();
    navigate(Path.game(gameId).publishers());
  };

  const isSigned = publisherGame.status === "Signed";

  return (
    <>
      <Card elevation={1} square={false}>
        <Box p={2}>
          <PublisherLogo url={publisherGame.logoUrl} name={publisherGame.publisherName} />
          <Stack justifyContent="space-between" direction="row">
            <Typography variant="h5" textAlign="left" color="text.secondary" noWrap>
              {publisherGame.publisherName}
            </Typography>
            {publisherGame.status === "ShortListed" && (
              <IconButton onClick={removeShortlistedPublisher}>
                <Delete />
              </IconButton>
            )}
          </Stack>
          {!isSigned && <FollowUp publisherGame={publisherGame} />}
        </Box>
      </Card>
      <Box pt={1} />
      <Card elevation={1} square={false}>
        <List>
          <Box p={1}>
            <PublisherInterestStatusSelect value={publisherGame.status} onChange={saveUpdate} />
            {publisherGame.status !== "ShortListed" && (
              <PublisherInterestRulesSentSelect
                gameId={gameId}
                value={publisherGame.rules}
                onChange={updateRulesSent}
              />
            )}
            {publisherGame.status !== "ShortListed" && (
              <PublisherInterestSellsheetSentSelect
                gameId={gameId}
                value={publisherGame.sellsheet}
                onChange={updateSellsheetSent}
              />
            )}
            {publisherGame.status !== "ShortListed" && (
              <EditableSwitch
                initialValue={publisherGame.sentPNP}
                offLabel="Publisher does not have PNP files"
                onLabel="Publisher has been sent PNP files"
                title="PNP Files"
                onUpdate={togglePnpFiles}
              />
            )}
            <ExternalInventorRelationsLinks publisherGame={publisherGame} />
          </Box>
        </List>
      </Card>
      <Box pt={1} />
      <Card elevation={1} square={false}>
        <Box p={2}>
          <Stack pt={1} direction="row" justifyContent="space-between" alignItems="center">
            <Typography variant="h5" textAlign="left" color="text.secondary" noWrap>
              Activity
            </Typography>
            <Stack direction="row">
              <Button onClick={refetchHandler}>{isRefetching ? <CircularProgress size={16} /> : <Cached />}</Button>
              <AddPublisherUpdate publisherGame={publisherGame} userId={profile.id} />
            </Stack>
          </Stack>
          <Box pt={1}>
            <Timeline
              sx={{
                [`& .${timelineItemClasses.root}:before`]: {
                  flex: 0,
                  padding: 0,
                },
              }}
            >
              {updates.map((update, i) => (
                <PublisherUpdate
                  key={update.id}
                  updateId={update.id}
                  notLast={i < updates.length - 1}
                  publisherGame={publisherGame}
                  userId={profile.id}
                />
              ))}
              {updates.length === 0 && <Typography>No updates yet.</Typography>}
            </Timeline>
          </Box>
        </Box>
      </Card>
    </>
  );
};

import React from "react";
import { Box, Typography } from "@mui/material";
import { formatFullDate } from "../core/date-formatting";

/**
 * @param {object} props
 * @param {object} props.timestamp
 */
export const LastUpdated = ({ timestamp }) => {
  return (
    <Box pr={1}>
      <Typography variant="body2" color="text.secondary" textAlign="right" fontStyle="italic">
        Last updated: {formatFullDate(timestamp)}
      </Typography>
    </Box>
  );
};

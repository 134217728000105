import React from "react";
import { ListDetail } from "../web/ListDetail";
import { SellsheetList } from "./SellsheetList";
import { SellsheetFromRoute } from "./SellsheetPreview";
import { useGameIdFromRoute } from "../games/WithGameIdFromRoute";
import { Path } from "../Path";
import { useGameSellsheets } from "@seabrookstudios/pitch2table";

export const SellsheetListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data: sellsheets = [] } = useGameSellsheets(gameId);

  const SelectedSellsheet = () => <SellsheetFromRoute />;

  const ListView = () => <SellsheetList gameId={gameId} sellsheets={sellsheets} back={Path.game(gameId).link()} />;

  return <ListDetail ListView={ListView} DetailView={SelectedSellsheet} />;
};

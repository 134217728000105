import { useParams } from "react-router-dom";
import { s2PublisherId } from "../util/type-conversion";
import { s2GameId, s2UserId } from "@seabrookstudios/types";

/**
 * @returns {import("@seabrookstudios/types").GameId}
 */
export const useGameIdFromRoute = () => {
  return s2GameId(useParams().gameId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").AttachmentId}
 */
export const useAttachmentIdFromRoute = () => {
  return /** @type {import("@seabrookstudios/pitch2table-core").AttachmentId} */ (useParams().attachmentId || "");
};

/**
 * @returns {import("@seabrookstudios/pitch2table-core").PublisherId}
 */
export const usePublisherIdFromRoute = () => {
  return s2PublisherId(useParams().publisherId || "");
};

/**
 * @returns {import("@seabrookstudios/types").UserId}
 */
export const useUserIdIdFromRoute = () => {
  return s2UserId(useParams().userId || "");
};

/**
 * @param {import("@seabrookstudios/types").GameId?} [gameId]
 */
export const PublicGameByGameId = (gameId) => ["public-game", gameId];

/**
 * @param {import("@seabrookstudios/types").GameId?} [gameId]
 */
export const PublisherInterestByGameId = (gameId) => ["publisher-interest", gameId];

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId?} [publisherId]
 * @param {import("@seabrookstudios/types").GameId?} [gameId]
 */
export const PublisherGameByGameId = (publisherId, gameId) => ["publisher-game", publisherId, gameId];

/**
 * @param {import("@seabrookstudios/types").GameId} [gameId]
 */
export const DesignersByGameId = (gameId) => ["designers", gameId];

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const AllSellsheetsByGameId = (gameId) => ["sellsheets", gameId];

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const LatestRulesByGameId = (gameId) => ["latest-rules", gameId];

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const LatestSellsheetByGameId = (gameId) => ["latest-sellsheet", gameId];

/**
 * @param {import("@seabrookstudios/types").GameId} gameId
 */
export const PrivateGameByGameId = (gameId) => ["private-game", gameId];

export const MyPublishers = () => ["my-publishers"];
export const ThisUser = () => ["this-user"];

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} [publisherId]
 */
export const PublisherByPublisherId = (publisherId) => ["publisher", publisherId];

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId?} [publisherId]
 */
export const PublisherSubmissionsByPublisherId = (publisherId) => ["publisher-submissions", publisherId];

import React from "react";

/**
 * @param {object} props
 * @param {string|undefined} [props.subject]
 */
export const SupportEmail = ({ subject }) => (
  <a href={`mailto:support@pitch2table.com${subject ? `?subject=${subject}` : ""}`}>support@pitch2table.com</a>
);

export const DiscordLink = () => (
  <a href="https://discord.gg/a8dgfXeYyu" target="_blank" rel="noreferrer">
    discord
  </a>
);

import React, { useCallback } from "react";
import { ListDetail } from "../web/ListDetail";
import { useGameIdFromRoute } from "./WithGameIdFromRoute";
import { PublisherInterestList } from "./PublisherInterestList";
import { PublisherInterestInGame } from "./PublisherInterestInGame";
import { useGamePublishers } from "@seabrookstudios/pitch2table";
import { useFilters } from "../core/useFilters";
import { PublisherListFilters } from "./publisher-interest/filters";

export const PublisherInterestListAndDetail = () => {
  const gameId = useGameIdFromRoute();
  const { data: interest = [], refetch, isLoading, isRefetching } = useGamePublishers(gameId);
  const { filtered, filter, visibleFilters, onFilterClicked } = useFilters(interest, PublisherListFilters, "interest");

  const SelectedGame = useCallback(() => <PublisherInterestInGame />, []);
  const ListView = () => (
    <PublisherInterestList
      filter={filter}
      filters={visibleFilters}
      onFilterClicked={onFilterClicked}
      publishers={filtered}
      refetch={refetch}
      isLoading={isLoading}
      isRefetching={isRefetching}
    />
  );

  return <ListDetail ListView={ListView} DetailView={SelectedGame} />;
};

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").DesignerGameStatus, string>}
 */
export const StatusToText = {
  Idea: "CONCEPT",
  InDevelopmentEarly: "DEV - EARLY",
  InDevelopmentMid: "DEV - MID",
  InDevelopmentLate: "DEV - LATE",
  PitchingNow: "PITCHING",
  Published: "PUBLISHED",
  Signed: "SIGNED",
};

/**
 * @type {Record<import("@seabrookstudios/pitch2table-core").PublisherGameStatus,"error"|"success"|"default"|"warning"|"info"|"primary"|"secondary">}
 */
const statusToColour = {
  ShortListed: "info",
  PitchSent: "info",
  PitchReceived: "info",
  InProgress: "primary",
  MaterialsRequested: "secondary",
  UnderConsideration: "primary",
  InContractNegotiation: "primary",
  Declined: "error",
  Signed: "success",
  Lost: "default",
  Withdrawn: "default",
};

/**
 * @param {import("@seabrookstudios/pitch2table-core").PublisherGameStatus?} [status]
 * @returns {"error"|"success"|"default"|"warning"|"info"|"primary"|"secondary"}
 */
export const formatPublisherStatusColour = (status) => {
  if (!status) {
    return "default";
  }

  if (statusToColour[status]) {
    return statusToColour[status];
  }

  return "default";
};

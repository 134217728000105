import React from "react";
import { Skeleton, Typography } from "@mui/material";
import { useContact } from "@seabrookstudios/pitch2table";

/**
 * @param {object} props
 * @param {import('@seabrookstudios/types').UserId} props.userId
 */
export const UserName = ({ userId }) => {
  const { data: profile } = useContact(userId);

  if (!profile) {
    return <Skeleton />;
  }

  return (
    <Typography variant="inherit" component="span">
      {profile.firstName} {profile.lastName}
    </Typography>
  );
};

/**
 * @param {any[]} submissions
 */
export const formatSubmissions = (submissions = []) => {
  if (submissions.length === 0) {
    return `No submissions`;
  }

  if (submissions.length === 1) {
    return `1 submission`;
  }

  return `${submissions.length} submissions`;
};

/**
 * @param {any[]} documents
 */
export const formatDocuments = (documents = []) => {
  if (documents.length === 0) {
    return `No documents`;
  }

  if (documents.length === 1) {
    return `1 document`;
  }

  return `${documents.length} documents`;
};

import React, { useState } from "react";
import { FullScreenDialog } from "../../core/FullScreenDialog";
import Box from "@mui/material/Box";
import Card from "@mui/material/Card";
import { DateTime } from "luxon";
import { usePublisherAndContacts, useAddPublisherGameUpdateV } from "@seabrookstudios/pitch2table";
import { SmartTextField } from "../../core/SmartTextField";
import { PublisherUpdateFromSelect } from "./PublisherUpdateFromSelect";
import { Checkbox, FormControlLabel, FormGroup, Stack, Typography } from "@mui/material";
import { DatePicker } from "@mui/x-date-pickers/DatePicker";
import { formatFullDate } from "../../core/date-formatting";
import { FollowUpStatusToDaysDefaults, formatPublisherStatus } from "@seabrookstudios/pitch2table-core";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").UserId} props.userId
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisherGame
 */
export const AddPublisherUpdate = ({ userId, publisherGame }) => {
  const { data: publisherAndContacts } = usePublisherAndContacts(publisherGame.publisherId);

  const updatePublisherGame = useAddPublisherGameUpdateV(publisherGame.id);

  const contacts = publisherAndContacts ? publisherAndContacts[1] : [];

  // the one we use for the date controls
  const [date, setDate] = useState(DateTime.now());
  // the one we send to the server
  const [newDate, setNewDate] = useState(DateTime.now().toMillis());
  const [updateText, setUpdateText] = useState("");
  const [from, setFrom] =
    /** @type {[(import('@seabrookstudios/types').UserId|'publisher'),import('react').Dispatch<import('react').SetStateAction<import('@seabrookstudios/types').UserId|'publisher'>>]} */ (
      useState(userId)
    );

  const canUpdateFollowUp = !!FollowUpStatusToDaysDefaults[publisherGame.status];
  const [followUp, setFollowUp] = useState(canUpdateFollowUp);

  /**
   * @param {import('@seabrookstudios/types').UserId|'publisher'} from
   */
  const onFromSelect = (from) => {
    setFrom(from);
  };

  const saveUpdate = () => {
    /**
     * @type {import("@seabrookstudios/pitch2table-core").AddPublisherGameUpdateRequest}
     */
    const request = {
      status: null,
      update: updateText,
      sharedWithDesigner: true,
      sharedWithPublisher: true,
      from,
      timestamp: newDate,
      followUp,
    };

    updatePublisherGame.mutate(request);
  };

  const onUpdateDate = (componentDate) => {
    setNewDate(componentDate.toMillis());
    setDate(componentDate);
  };

  const canSave = updateText.length > 0;

  return (
    <FullScreenDialog
      fullscreen={false}
      title="Add Publisher Update"
      actionText="SAVE"
      canSave={!canSave}
      onSaveHandler={saveUpdate}
      isBusy={updatePublisherGame.isPending}
    >
      <Card elevation={2}>
        <Box p={1}>
          <Stack direction="row">
            <DatePicker defaultValue={date} label="Date" onChange={onUpdateDate} />
            <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
              <Typography>{formatFullDate(newDate)}</Typography>
            </Box>
          </Stack>
          <PublisherUpdateFromSelect
            value={from}
            userId={userId}
            publisherContactUserIds={contacts.map((contact) => contact.id)}
            publisherGame={publisherGame}
            onChange={onFromSelect}
          />
          <SmartTextField
            value={updateText}
            onChange={setUpdateText}
            onEnterDisabled
            multiline
            minRows={5}
            autoFocus
            label="Update"
          />

          <FormGroup>
            <FormControlLabel
              control={
                <Checkbox checked={followUp} disabled={!canUpdateFollowUp} onChange={() => setFollowUp((x) => !x)} />
              }
              label={
                canUpdateFollowUp
                  ? `Schedule next follow up reminder for ${
                      FollowUpStatusToDaysDefaults[publisherGame.status]
                    } days from today.`
                  : `No follow up is scheduled for pitches in ${formatPublisherStatus(publisherGame.status)} status`
              }
            />
          </FormGroup>
        </Box>
      </Card>
    </FullScreenDialog>
  );
};

import React from "react";
import { useAttachmentIdFromRoute } from "../games/WithGameIdFromRoute";
import { EmptyDetailCard } from "../core/EmptyDetailCard";
import { DocumentFromRoute } from "../sellsheets/ContractFromRoute";

export const MyDocumentsDetailFromRoute = () => {
  const attachmentId = useAttachmentIdFromRoute();

  if (!attachmentId) {
    return <EmptyDetailCard text="Select a contract from the list on the left." />;
  }

  return <DocumentFromRoute />;
};

import React from "react";
import { useMyDocumentsRelatingToPublisher } from "@seabrookstudios/pitch2table";
import { Path } from "../Path";
import { useNavigate } from "react-router-dom";
import { Box, Card, CircularProgress, List, ListItemButton, ListItemText } from "@mui/material";
import { formatFullDate } from "@seabrookstudios/pitch2table-core";
import { formatDocuments } from "./model";
import { ListHeaderWithExpand } from "../core/ListHeaderWithExpand";
import { usePublisherIdFromRoute } from "../games/WithGameIdFromRoute";

/**
 * @param {Object} props
 * @param {import("@seabrookstudios/pitch2table-core").NonGameContract} props.document
 */
const MyDocumentListEntry = ({ document }) => {
  const publisherId = usePublisherIdFromRoute();

  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Path.publisher(publisherId).document(document.id));
  };

  return (
    <ListItemButton alignItems="flex-start" onClick={onClickHandler}>
      <ListItemText primary={document.name || "No title"} secondary={`Signed ${formatFullDate(document.signedDate)}`} />
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
export const MyDocumentsWithPublisher = ({ publisherId }) => {
  const { data: documents = [], isLoading } = useMyDocumentsRelatingToPublisher(publisherId);

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <CircularProgress />
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <Box p={2}>
        <List>
          <ListHeaderWithExpand
            title="Documents"
            secondary={formatDocuments(documents)}
            to={Path.publisher(publisherId).documents()}
          />
          {documents.map((document) => (
            <MyDocumentListEntry key={document.id} document={document} />
          ))}
        </List>
      </Box>
    </Card>
  );
};

import React, { useRef } from "react";
import { Box, Icon, Stack, Typography } from "@mui/material";
import { GameNameHeading } from "../core/font-sizes";
import { EditOutlined } from "@mui/icons-material";
import { useHover } from "usehooks-ts";

/**
 * @param {object} props
 * @param {string} props.text
 * @param {Function?} [props.onSave]
 * @param {boolean} [props.disabled]
 * @returns {React.JSX.Element}
 */
export const EditableGameName = ({ text, onSave = null, disabled = false }) => {
  const hoverRef = useRef(null);
  const isHover = useHover(hoverRef);

  return (
    <Typography variant="h4" textAlign="left" color="text.secondary" noWrap>
      <Stack direction="row">
        <Box ref={hoverRef}>{text}</Box>
        <Box pl={1} display="flex" justifyContent="center" alignItems="center">
          {isHover && !disabled ? <EditOutlined /> : <Icon />}
        </Box>
      </Stack>
    </Typography>
  );
};

/**
 * @param {object} props
 * @param {string} props.text
 * @returns {React.JSX.Element}
 */
export const PublicImmutableGameName = ({ text }) => {
  return (
    <GameNameHeading>
      <Box>{text}</Box>
    </GameNameHeading>
  );
};

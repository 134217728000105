import React from "react";
import { Link, ListItem, ListItemButton, ListItemIcon, ListItemText } from "@mui/material";
import { ArrowForwardOutlined } from "@mui/icons-material";
import { useDesigners } from "./queries";
import { Link as RouterLink } from "react-router-dom";
import { Path } from "../Path";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/types").GameId} props.gameId
 * @param {import("@seabrookstudios/pitch2table-core").GameDesigners[]} props.designers
 */
export const GameDesignersMenuItem = ({ gameId, designers }) => {
  const { data } = useDesigners(designers);

  if (!data) {
    return <p>no data</p>;
  }

  return (
    <ListItem>
      <ListItemText
        primary={data.length === 1 ? "Designer" : "Designers"}
        secondary={data.map(({ firstName, lastName }) => `${firstName} ${lastName}`).join(", ")}
      />
      <Link to={Path.game(gameId).designers()} component={RouterLink}>
        <ListItemIcon>
          <ListItemButton>
            <ArrowForwardOutlined />
          </ListItemButton>
        </ListItemIcon>
      </Link>
    </ListItem>
  );
};

import React, { useContext } from "react";
import Image from "mui-image";
import { Avatar, Link, ListItemButton, Stack, Paper, Badge } from "@mui/material";
import Settings from "@mui/icons-material/Settings";
import BusinessIcon from "@mui/icons-material/Business";
import { useLocation, useNavigate } from "react-router-dom";
import LogoutButton from "../login/LogoutButton";
import { Path } from "../Path";
import { Link as RouterLink } from "react-router-dom";
import { AccountContext } from "./AccountProvider";
import { ModeButton } from "./ModeButton";
import { useInvitesForUser } from "@seabrookstudios/pitch2table";
import { usePublisher } from "../publisher/queries";
import logo from "../assets/logo.png";
import { GoMobileButton } from "../general/GetMobilePage";
import { FeedbackAndSupportButton } from "../general/FeedbackAndSupportPage";
import { SideMenuOption } from "./SideMenuOption";
import { UpdatesButton } from "../general/UpdatesPage";
import { Article } from "@mui/icons-material";

/**
 * @param {string} label
 * @param {string} tooltip
 * @param {import("react-router-dom").To} to
 * @param {() => React.JSX.Element} Icon
 */
const makeMenuOption = (label, tooltip, to, Icon) => {
  const MenuOption = () => {
    const location = useLocation();
    const navigate = useNavigate();

    const onClickHandler = () => {
      navigate(to);
    };

    return (
      <SideMenuOption
        onClick={onClickHandler}
        tooltip={tooltip}
        label={label}
        // @ts-ignore
        selected={location.pathname.startsWith(to.pathname)}
      >
        <Icon />
      </SideMenuOption>
    );
  };

  return MenuOption;
};

const PublisherHomeButton = () => {
  const location = useLocation();

  const { publisherId } = useContext(AccountContext);
  const { data: publisher } = usePublisher(publisherId);

  return (
    <ListItemButton
      sx={{ display: "flex", padding: 1, justifyContent: "center", alignItems: "center" }}
      selected={location.pathname === "/"}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.Home}
        aria-label="go publisher home"
        aria-controls="publisher home"
        sx={{ flexGrow: 1 }}
      >
        <Avatar src={publisher && publisher.logoUrl} />
      </Link>
    </ListItemButton>
  );
};

const UserHomeButton = makeMenuOption("Home", "Head back to your dashboard", Path.games(), () => (
  <Image alt="My Games" src={logo} fit="contain" width={32} height={32} />
));
const MyPublishers = makeMenuOption("Publishers", "View your publishers", Path.publishers(), () => <BusinessIcon />);
const MyDocuments = makeMenuOption("Contracts", "View your contracts", Path.documents(), () => <Article />);

const UserSettingsButton = () => {
  const { data: invites = [] } = useInvitesForUser();
  const location = useLocation();

  const navigate = useNavigate();

  const onClickHandler = () => {
    navigate(Path.settings().none());
  };

  return (
    <SideMenuOption
      label="Settings"
      tooltip="Edit account and application settings"
      onClick={onClickHandler}
      selected={location.pathname.startsWith(Path.settings().none())}
    >
      <Badge color="primary" badgeContent={invites.length}>
        <Settings />
      </Badge>
    </SideMenuOption>
  );
};

const PublisherSettingsButton = () => {
  const location = useLocation();

  return (
    <ListItemButton
      sx={{ display: "flex", justifyContent: "center", alignItems: "center" }}
      selected={location.pathname.startsWith(Path.Settings)}
    >
      <Link
        color="inherit"
        component={RouterLink}
        to={Path.Settings}
        aria-label="go to publisher settings"
        aria-controls="publisher settings"
        sx={{ flexGrow: 1 }}
      >
        <Settings />
      </Link>
    </ListItemButton>
  );
};

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").PublisherId} props.publisherId
 */
const UserMenuBase = () => {
  const { mode } = useContext(AccountContext);

  return (
    <Paper style={{ minHeight: "100vh", borderTopLeftRadius: 0, borderBottomLeftRadius: 0 }}>
      <Stack sx={{ width: { xs: 60, md: 175, lg: 200 } }} direction="column">
        <Stack
          spacing={2}
          pt={2}
          pb={2}
          sx={{
            alignItems: { xs: "center", md: "flex-start" },
            mt: "auto",
            flexGrow: 1,
          }}
        >
          {mode === "Publisher" ? <PublisherHomeButton /> : <UserHomeButton />}
          <MyPublishers />
          <MyDocuments />
          {/* {mode === "Designer" && <MyGamesButton />} */}
          {false && <ModeButton />}
          {mode === "Publisher" ? <PublisherSettingsButton /> : <UserSettingsButton />}
          <GoMobileButton />
          <FeedbackAndSupportButton />
          <UpdatesButton />
          <LogoutButton />
        </Stack>
      </Stack>
    </Paper>
  );
};

const UserMenu = () => <UserMenuBase />;

export default UserMenu;

import React from "react";
import { Alert, Box, CircularProgress } from "@mui/material";
import NotificationImportantIcon from "@mui/icons-material/NotificationImportant";
import { useDeleteFollowUp, useUpdateFollowUp } from "@seabrookstudios/pitch2table";
import { EditableDate } from "../../core/EditableDate";
import { Delete } from "@mui/icons-material";
import { LoadingButton } from "@mui/lab";
import { formatFollowUpWithDate } from "@seabrookstudios/pitch2table-core";
import { getSeverity } from "./follow-up-severity";

/**
 * @param {object} props
 * @param {import("@seabrookstudios/pitch2table-core").DesignerPublisherGameView} props.publisherGame
 */
export const FollowUp = ({ publisherGame }) => {
  const updateFollowUp = useUpdateFollowUp(publisherGame.id);
  const deleteFollowUp = useDeleteFollowUp(publisherGame.id);

  /**
   * @param {number} timestamp
   */
  const updateNextFollowUp = async (timestamp) => {
    await updateFollowUp.mutateAsync({ timestamp });
  };

  const clearNextFollowUp = async () => {
    await deleteFollowUp.mutateAsync();
  };

  return (
    <Box pt={2}>
      <Alert
        icon={
          updateFollowUp.isPending ? <CircularProgress size={20} /> : <NotificationImportantIcon fontSize="inherit" />
        }
        severity={getSeverity(publisherGame.nextAction)}
        action={
          publisherGame.nextAction > 0 && (
            <LoadingButton onClick={clearNextFollowUp} aria-label="delete-follow-up" loading={deleteFollowUp.isPending}>
              <Delete />
            </LoadingButton>
          )
        }
      >
        <EditableDate
          timestamp={publisherGame.nextAction}
          onSave={updateNextFollowUp}
          title={formatFollowUpWithDate(publisherGame)}
          popupTitle={"Next follow up date"}
          isBusy={updateFollowUp.isPending}
          disablePast
        />
      </Alert>
    </Box>
  );
};

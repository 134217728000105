import React from "react";
import Add from "@mui/icons-material/Add";
import AppBar from "@mui/material/AppBar";
import Box from "@mui/material/Box";
import Link from "@mui/material/Link";
import Button from "@mui/material/Button";
import CircularProgress from "@mui/material/CircularProgress";
import CloseIcon from "@mui/icons-material/Close";
import Dialog from "@mui/material/Dialog";
import IconButton from "@mui/material/IconButton";
import Slide from "@mui/material/Slide";
import Toolbar from "@mui/material/Toolbar";
import Typography from "@mui/material/Typography";
import { background } from "../theme";
import { DeleteForever } from "@mui/icons-material";
import { useTheme } from "@mui/material";

const Transition = React.forwardRef(function Transition(props, ref) {
  // @ts-ignore
  return <Slide direction="up" ref={ref} {...props} />;
});

/**
 * @param {object} props
 * @param {string} props.title
 * @param {string} [props.actionText]
 * @param {Function} [props.onSaveHandler]
 * @param {Function} [props.onDeleteHandler]
 * @param {boolean} [props.canSave]
 * @param {boolean} [props.fullscreen]
 * @param {boolean} [props.isBusy]
 * @param {import("react").ReactNode} [props.children]
 * @param {import("react").ReactNode} [props.CustomControl]
 * @param {"flex-end"|"center"|"flex-start"} [props.iconAlign]
 * @param {() => React.JSX.Element} [props.Icon]
 * @param {boolean} [props.textButton]
 * @param {"small"|"medium"|"large"} [props.size]
 */
export const FullScreenDialog = ({
  fullscreen = true,
  isBusy = false,
  title,
  actionText,
  onSaveHandler,
  onDeleteHandler,
  canSave,
  children,
  iconAlign = "flex-end",
  Icon = () => <Add />,
  textButton = false,
  size = "medium",
  CustomControl = null,
}) => {
  const theme = useTheme();
  const [open, setOpen] = React.useState(false);

  const handleClickOpen = () => {
    setOpen(true);
  };

  const handleClose = () => {
    setOpen(false);
  };

  const handleAction = async (ev) => {
    ev.preventDefault();

    if (onSaveHandler) {
      await onSaveHandler();
    }

    handleClose();
  };

  const handleDelete = async (ev) => {
    ev.preventDefault();

    if (onDeleteHandler) {
      await onDeleteHandler();
    }

    handleClose();
  };

  return (
    <>
      {CustomControl && <Box onClick={handleClickOpen}>{CustomControl}</Box>}
      {textButton && !CustomControl && (
        <Link onClick={handleClickOpen} sx={{ cursor: "pointer" }}>
          <Typography variant="body2" component="span">
            edit
          </Typography>
        </Link>
      )}
      {!textButton && !CustomControl && (
        <Box justifyContent={iconAlign} display="flex">
          <Button onClick={handleClickOpen} size={size}>
            <Icon />
          </Button>
        </Box>
      )}
      <Dialog
        fullScreen={fullscreen}
        open={open}
        onClose={handleClose}
        // @ts-expect-error type mismatch
        TransitionComponent={Transition}
        PaperProps={{ sx: { backgroundColor: background, minWidth: "50%" } }}
      >
        <AppBar sx={{ position: "relative" }}>
          <Toolbar>
            <IconButton edge="start" color="inherit" onClick={handleClose} aria-label="close">
              <CloseIcon />
            </IconButton>
            <Typography sx={{ ml: 2, flex: 1 }} variant="h6" component="div">
              {title}
            </Typography>
            {onDeleteHandler && (
              <Button color="inherit" onClick={handleDelete} aria-label="delete">
                <DeleteForever />
              </Button>
            )}
            {actionText && (
              <Button
                autoFocus
                variant="outlined"
                onClick={handleAction}
                disabled={canSave}
                color="inherit"
                theme={{
                  ...theme,
                  palette: {
                    ...theme.palette,
                    action: { ...theme.palette.action, disabled: "#000000FF", disabledBackground: "#000000FF" },
                  },
                }}
                sx={{ disabled: { borderColor: "#000000FF", color: "#000000FF" } }}
              >
                {isBusy ? <CircularProgress size={16} /> : actionText}
              </Button>
            )}
          </Toolbar>
        </AppBar>
        <Box p={2}>{children}</Box>
      </Dialog>
    </>
  );
};

import React from "react";
import { Box, Card, Chip, CircularProgress, List, ListItem, ListItemIcon, ListItemText } from "@mui/material";
import {
  updateContactNumber,
  updateFirstName,
  updateLastName,
  updatePreferredName,
  updatePronouns,
} from "../users/users-service";
import { UpdatableNameTextField } from "./UpdatableNameTextField";
import { ThisUser } from "../games/query-keys";
import { useUserProfile } from "../user/queries";
import { UserAvatarCardCover } from "../users/UserAvatar";
import { Check } from "@mui/icons-material";

const FirstNameTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="First Name"
      helperText="Your first name, as you'd like it to appear on the box"
      initialValue={initialValue}
      updateFunction={updateFirstName}
      queryKey={ThisUser()}
    />
  );
};

const LastNameTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Last Name"
      helperText="Your last name, as you'd like it to appear on the box"
      initialValue={initialValue}
      updateFunction={updateLastName}
      queryKey={ThisUser()}
    />
  );
};

const PreferredNameTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Preferred Name"
      helperText="The name you'd like people to call you."
      initialValue={initialValue}
      updateFunction={updatePreferredName}
      queryKey={ThisUser()}
    />
  );
};

const PronounsTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Pronouns"
      helperText="What are your pronouns?"
      initialValue={initialValue}
      updateFunction={updatePronouns}
      queryKey={ThisUser()}
    />
  );
};

const ContactNumberTextField = ({ initialValue }) => {
  return (
    <UpdatableNameTextField
      label="Contact Number"
      helperText="Your preferred contact number (mobile or landline)"
      initialValue={initialValue}
      updateFunction={updateContactNumber}
      queryKey={ThisUser()}
    />
  );
};

export const AboutYou = () => {
  const { data: contact, isLoading } = useUserProfile();

  if (isLoading) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <CircularProgress />
        </Box>
      </Card>
    );
  }

  if (!contact) {
    return (
      <Card elevation={1} square={false}>
        <Box p={2} justifyContent="center">
          <span>An error occurred, please refresh the page.</span>
        </Box>
      </Card>
    );
  }

  return (
    <Card elevation={1} square={false}>
      <List>
        <Box p={1}>
          <UserAvatarCardCover contact={contact} editable />
        </Box>
        <ListItem>
          <FirstNameTextField initialValue={contact.firstName} />
        </ListItem>
        <ListItem>
          <LastNameTextField initialValue={contact.lastName} />
        </ListItem>
        <ListItem>
          <PreferredNameTextField initialValue={contact.preferredName} />
        </ListItem>
        <ListItem>
          <PronounsTextField initialValue={contact.pronouns} />
        </ListItem>
        <ListItem>
          <ListItemText primary="Email" secondary={contact.emailSet ? contact.email : "Pending"} />
          {contact.emailVerified && (
            <ListItemIcon>
              <Box justifyContent="center" alignItems="center" display="flex" flex={1}>
                <Chip icon={<Check />} label="VERIFIED" color="success" />
              </Box>
            </ListItemIcon>
          )}
        </ListItem>
        <ListItem>
          <ContactNumberTextField initialValue={contact.contactNumber} />
        </ListItem>
      </List>
    </Card>
  );
};
